import React, { Fragment } from 'react';
import sampleImage from './../../img/sample.jpg';
import "./styles.css";

const HealthCare = (props) => {
  return (
    <Fragment>
      <div className="product-sidebar">
        <ul className="sidebar-list">
          <li className="list-item"><a href="#" className='sidebar-link btn-active'>Overview</a></li>
          <li className="list-item"><a href="#" className='sidebar-link'>Application</a></li>
          <li className="list-item"><a href="#" className='sidebar-link'>Use Cases</a></li>
          <li className="list-item"><a href="#" className='sidebar-link'>Next Steps</a></li>
        </ul>
      </div>

    <main id="main">
      <section className="breadcrumbs">
        <div className="container">
        </div>
      </section>

      <section class="features">
        <div class="container">

        <div class="row section-bgprimary" data-aos="fade-up">
          <div class="col-md-6 column-padding">
            <h3>Transportation</h3>
            <p class="p-text">
            Transforming transportation with Enterprise AI 
            </p>
          </div>
          <div class="col-md-6">
            <img src={sampleImage} class="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </section>

      <section className="about" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 pt-4 pt-lg-0">
              <h3>Improve Fleet Management and Customer Service</h3>
              <p>
              C3 AI helps transportation and logistics companies predict asset failure risk, address inefficiencies, and enhance operational visibility.
              </p>
            </div>
          </div>
          <div className="row row-padding">
            <div className="col-lg-12 pt-4 pt-lg-0">
              <img src={sampleImage} class="img-fluid" alt="" />
          </div>
          </div>
        </div>
      </section>

      <section className="about" data-aos="fade-up">
        <div className="container">
          <div className='row'>
            <div className="col-lg-12">
            <h3>Applications</h3>
            </div>
          </div>
          <div className='row row-padding'>
            <div className="col-lg-6">
              <i className="icofont-check-circled"></i>
              <p className='color-white'>Aggregate petabyte-scale data from fleet-deployed sensors, devices, enterprise systems, and operational systems to generate accurate predictions of vehicle and asset failure. C3 AI Reliability provides planners and operators with comprehensive insight into asset risk, enabling them to maintain higher levels of asset availability, deliver high-quality services, and reduce maintenance costs.</p>
              <button class="button-28" role="button">Learn More</button>
            </div>
            <div className="col-lg-6">
              <i className="icofont-check-circled"></i>
              <p className="color-white">Leverage real-time AI/machine learning to drive operational excellence and improve profitability across sales, marketing, and customer service. C3 AI CRM delivers AI-generated forecasts and scores in real time using insights from internal and external data sets.</p>
              <button class="button-28" role="button">Learn More</button>
            </div>
          <div className='row row-padding'>
            <div className="col-lg-6">
              <i className="icofont-check-circled"></i>
              <p className="color-white">Reduce energy costs and improve warehouse and building operations through real-time tracking, analytics, and optimization. C3 AI Energy Management uses machine learning techniques to enable accurate forecasting, benchmarking, demand response, and anomaly detection to lower costs, improve operations, and meet energy-efficiency goals.</p>
              <button class="button-28" role="button">Learn More</button>
            </div>
            <div className="col-lg-6">
              <i className="icofont-check-circled"></i>
              <p className="color-white">Reduce inventory holding costs and improve cash flow and supply chain visibility. C3 AI Inventory Optimization applies advanced machine learning to analyze variability in demand, supplier delivery times, and quality issues to build real-time recommendations and monitoring, so users can set optimization by confidence level and receive real-time notifications and root-cause analysis.</p>
              <button class="button-28" role="button">Learn More</button>
            </div>
          </div>
          </div>
        </div>
      </section>

      <section className="services section-bgprimary">
        <div className="container">
       
        <div className="row">
          <h4 className="col-md-6 col-lg-6 title-size">Use Cases </h4>
        </div>

        <div className='row row-padding'>
          <div className="col-lg-6">
            <i className="icofont-check-circled"></i>
            <h4 className="font-bold">Vehicle Path Optimization</h4>
            <p className="color-white">Use AI-enabled delivery, vehicle routing, and scheduling to reduce operational costs and improve customer service.</p>
          </div>
          <div className="col-lg-6">
            <i className="icofont-check-circled"></i>
            <h4 className="font-bold">Predictive Workforce Planning</h4>
            <p className="color-white">Use AI-enabled demand forecasting for improved staff, vehicle, and capacity planning.</p>
          </div>
        </div>
      
        <div className='row row-padding'>
          <div className="col-lg-12">
            <h4 className="font-bold">Address use cases with the C3 AI Application Platform</h4>
            <p className="color-white">The C3 AI Application Platform is a purpose-built platform for developing and operating AI applications that address industry and company-specific use cases. It offers a cohesive, low-code/no-code development environment with a complete and comprehensive set of tools and services to design, build, deploy, and operate advanced, enterprise-scale AI applications.</p>
            <button class="button-28" role="button">Explore the AI Application Platform</button>
          </div>
        </div>
      </div>
      </section>
    </main>
    </Fragment>
  )
}

export default HealthCare