import React, { Fragment } from 'react';
import AIMLow_Header from '../../../img/Resources_Header.mp4';
import AIMLow_image from '../../assets/images/AIMLow.png';
import "../styles.css";

const AimlowProduct = () => {
  return (
    <Fragment>
      <div className="product-sidebar">
        <ul className="sidebar-list">
          <li className="list-item"><a href="./NERoML" className='sidebar-link btn-active'>Overview</a></li>
          <li className="list-item"><a href="./AIMLow_Products" className='sidebar-link'>Product </a></li>
          <li className="list-item"><a href="./NERoML_freedemo" className='sidebar-link btn-active'>Free Demo</a></li>
        </ul>
      </div>

      <section id="hero" className="d-flex justify-content-center align-items-center">
        <video autoPlay muted loop>
          <source src={AIMLow_Header} type="video/mp4" />
        </video>
        <div id="heroCarousel" className="container carousel carousel-fade" data-ride="carousel">

          <div className="carousel-item active">
            <div className="carousel-container">
              <img src={AIMLow_image} alt={AIMLow_image} className="main-logo"/>
            </div>
          </div>

        </div>
      </section>

    <main id="main">

    <section class="features">
      <div class="container container-margin">
        <div class="row center-text" data-aos="fade-up">
          <p className="standard-color title-p">
            AIMLow Customizable Dashboard insights are automated requiring minimum human intervention through automation of workflows within the workbench. Making use of procurement analytics data models and procurement-specific scenarios, AIMLow can analyse a large set of data using machine learning to check for irregularities and fraud related anomalies, forecasts and more.  
          </p><br /><br />
        </div>
      </div>
    </section>

    <section class="features">
      <div class="container container-margin">
        <div class="row" data-aos="fade-up">
          <div class="col-md-12">
            {/* <img src={Graph_time_based} class="img-fluid" alt={Graph_time_based} /> */}
            <h1>(Image attached Dashboards/Widgets 3D Design)</h1>
          </div>
        </div>
      </div>
    </section>

    <section className="about" data-aos="fade-up">
      <div className="container container-margin">
        <div className="row">
          <div className="col-lg-12 pt-4 pt-lg-0">
            <p className="text-justify title-p standard-color">
              AIMLow Dashboards allows exploration and deep dives into a variety of customizable widgets , anticipate and adapt to changes within the data flow enabling visibility through your operations.
            </p><br /><br />
            <p className="text-justify title-p standard-color">
              Through large data analysis, data integration, machine learning with the use of NERoML and HyDDEP providing data transparency, insights and drill-in capabilities for dynamic Dashboard exploration.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="features">
      <div class="container container-margin">
        <div class="row" data-aos="fade-up">
          <div class="col-md-12">
            {/* <img src={Graph_time_based} class="img-fluid" alt={Graph_time_based} /> */}
            <h1>(Image attached Widgets Types 3D Design)</h1>
          </div>
        </div>
      </div>
    </section>

    <section className="about" data-aos="fade-up">
      <div className="container container-margin">
        <div className="row tb-padding">
          <div className="col-lg-12 pt-4 pt-lg-0">
            <h3>How AIMLow Benefits from NERoML & HyDDEP</h3><br /><br />
            <p className="text-justify title-p standard-color">
              These highly integrated products have resolved the industry-wide problem of disconnected and decentralized Structures. All Products use fundamental Machine Learning algorithms to better define data and discover anomalies which provide accurate visualizations, data representation and Data Structures.
            </p>
          </div>
        </div>
        <div className="row tb-padding">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <h3>NERoML with AIMLow</h3><br /><br />
            <p className="text-justify title-p standard-color">
              NERoML advanced graph algorithms that resolves complex machine learning anomalies with the use of Data Clustering, Similarity identification, Pattern Matching and more to provide meaningful data understanding through AIMLow's platform.
            </p>
          </div>
      
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <h3>HyDDEP with AIMLow</h3><br /><br />
            <p className="text-justify title-p standard-color">
              HyDDEP and AIMLow have revolutionized Business Analytics through Data quality, management and exploration to ingest meaningful information which is then Directly represented in AIMLow's Platform
            </p>
          </div>
        </div>
      </div>
    </section>

    </main>
    </Fragment>
  )
}

export default AimlowProduct