import React from "react";
import Navigation from "../components/home-page/navigation";
import GovernmentPage from "../components/industries/government/government__services_page";
import Footer from '../components/footer-section/footer';

const Government = () => {
  return (
    <div>
      <Navigation />
      <GovernmentPage />
      <Footer />
    </div>
  );
};

export default Government;
