import React, { Fragment } from 'react';
import Link_analysis from '../../../img/Link-analysis.png';
import Unified_Data_Model from '../../../img/Unified-Data-Model.png';
import Identification from '../../../img/Identification.png';
import Graph_time_based from '../../../img/Graph-&-time-based.png';
import Exploration from '../../../img/Exploration.png';
import Deep_Investigation_analysis from '../../../img/Deep-Investigation-analysis.png';
import NERoML_Header from '../../../img/NERoML_Header.mp4';
import NERoML_image from '../../assets/images/NERoML.png';
import "../styles.css";

const NERoMLProductPage = () => {
  return (
    <Fragment>
    <div className="product-sidebar">
      <ul className="sidebar-list">
        <li className="list-item"><a href="./NERoML" className='sidebar-link btn-active'>Overview</a></li>
        <li className="list-item"><a href="./NERoML_Products" className='sidebar-link'>Product </a></li>
        <li className="list-item"><a href="./NERoML_freedemo" className='sidebar-link btn-active'>Free Demo</a></li>
      </ul>
    </div>

    <section id="hero" className="d-flex justify-content-center align-items-center">
      <video autoPlay muted loop>
        <source src={NERoML_Header} type="video/mp4" />
      </video>
      <div id="heroCarousel" className="container carousel carousel-fade" data-ride="carousel">

        <div className="carousel-item active">
          <div className="carousel-container">
            <img src={NERoML_image} alt={NERoML_image} className="main-logo"/>
          </div>
        </div>

      </div>
    </section>

  <main id="main">

  <section class="features">
    <div class="container container-margin">
      <div class="row center-text" data-aos="fade-up">
        <h3 class="h3-title">Visualize dynamic data and accelerate efficacy</h3><br /><br />
      </div>
      <div class="row" data-aos="fade-up">
        <div class="col-md-7">
          <img src={Graph_time_based} class="img-fluid" alt={Graph_time_based} />
        </div>
        <div class="col-md-5">
          <h3>Network visualizations</h3>
          <ul className='accordion-list p-text margin-top'>
            <li>Use predictive relationship management across entities (people, organizations, 
            locations, things, and events) in a time-varying, graph network visualization that 
            captures and maintains all temporal and spatial relationships.</li>
            <li>Review all interactions across entities and visualize interaction density across time, 
            discovering patterns and temporal shifts.</li>
            <li>Drill-in, merge, or de-duplicate entities directly on the graph to manage and share knowledge with others.</li>
          </ul>
        </div>
      </div>

      <div class="row" data-aos="fade-up">
        <div class="col-md-7 order-1 order-md-2">
          <img src={Link_analysis} class="img-fluid" alt={Link_analysis} />
        </div>
        <div class="col-md-5 order-2 order-md-1">
          <h3>AI-powered link analysis</h3>
          <ul className='accordion-list p-text margin-top'>
            <li>Uncover hidden opportunities, targets, and complex patterns via AI-based investigative link analysis, 
            advanced graph algorithms, and rich tooling.</li>
            <li>Traverse step-wise through the network graph to incrementally identify new insights.</li>
            <li>Leverage AI recommendations to prioritize potential threats, mitigate risks, and resolve anomalies.</li>
          </ul>
        </div>
      </div>

      <div class="row" data-aos="fade-up">
        <div class="col-md-7">
          <img src={Exploration} class="img-fluid" alt={Exploration} />
        </div>
        <div class="col-md-5">
          <h3>Event-based exploration</h3>
          <ul className='accordion-list p-text margin-top'>
            <li>Visualize and analyze dynamic interaction data across time with the use of generated
            alert triggers to spot patterns and accelerate investigation efficacy</li>
            <li>Replay history of events, relationships, and interactions across all entities on the
            timeline represented by data.</li>
            <li>Leverage intuitive auto-clustering of entities and interactions to manage large data volumes</li>
          </ul>
        </div>
      </div>

      <div class="row" data-aos="fade-up">
        <div class="col-md-7 order-1 order-md-2">
          <img src={Unified_Data_Model} class="img-fluid" alt={Unified_Data_Model} />
        </div>
        <div class="col-md-5 order-2 order-md-1">
          <h3>Single unified data model</h3>
          <ul className='accordion-list p-text margin-top'>
            <li>Integrate and visualize data from multiple sources, including both structured and unstructured data.</li>
            <li>Update unified data model in near real-time with secure and scalable data ingest pipelines</li>
            <li>Explore raw or enriched data linked to all entities across the single data model, enabling deep and 
            thorough analysis</li>
          </ul>
        </div>
      </div>

      <div class="row" data-aos="fade-up">
        <div class="col-md-7">
          <img src={Identification} class="img-fluid" alt={Identification} />
        </div>
        <div class="col-md-5">
          <h3>AI-powered pattern identification</h3>
          <ul className='accordion-list p-text margin-top'>
            <li>Leverage AI-powered pattern mining across entities and interactions to reveal next best actions.</li>
            <li>Hone in on signals of relevance while exploring new hypotheticals.</li>
            <li>Flag issues for priority review and collaborate follow-ups with other users.</li>
          </ul>
        </div>
      </div>

      <div class="row" data-aos="fade-up">
        <div class="col-md-7 order-1 order-md-2">
          <img src={Deep_Investigation_analysis} class="img-fluid" alt={Deep_Investigation_analysis} />
        </div>
        <div class="col-md-5 order-2 order-md-1">
          <h3>Deep investigative analysis</h3>
          <ul className='accordion-list p-text margin-top'>
            <li>Start with one entity and build an investigation case through visual analysis and recommended data to review</li>
            <li>Explore all interaction types for any entity or focus only on a subset.</li>
            <li>Undo, redo, or jump to any investigative step via an activity log</li>
          </ul>
        </div>
      </div>

    </div>
  </section>

  </main>
  </Fragment>
  )
}

export default NERoMLProductPage;