import React, { Fragment } from 'react';
import Data_Image from '../../../img/Data-Image.jpg';
import Data_Lake from '../../../img/Data-Lake.jpg';
import Data_connector from '../../../img/Data-connector.jpg';
import Data_Object_model from '../../../img/Data-Object-model.jpg';
import HyDDEP_Header from '../../../img/HyDDEP_Header.mp4';
import HyDDEP_image from '../../assets/images/Hyddep.png';
import "../styles.css";

const HyddepPageProduct = () => {
  return (
    <Fragment>
    <div className="product-sidebar">
      <ul className="sidebar-list">
        <li className="list-item"><a href="./HyDDEP" className='sidebar-link btn-active'>Overview</a></li>
        <li className="list-item"><a href="./HyDDEP_products" className='sidebar-link'>Product</a></li>
        <li className="list-item"><a href="./NERoML_freedemo" className='sidebar-link'>Free Demo</a></li>
      </ul>
    </div>

  <section id="hero" className="d-flex justify-content-center align-items-center">
    <video autoPlay muted loop>
      <source src={HyDDEP_Header} type="video/mp4" />
    </video>
    <div id="heroCarousel" className="container carousel carousel-fade" data-ride="carousel">

    <div className="carousel-item active">
      <div className="carousel-container">
        <img src={HyDDEP_image} alt={HyDDEP_image} className="main-logo" />
      </div>
    </div>

    </div>
  </section>

  <main id="main">
  
  <section class="features">
    <div class="container container-margin">
      <div className="row">
        <div class="col-md-12">
          <h4 className="title-size">Data Integration Tool</h4>
          <p className="standard-color title-p">
            HyDDEP provides a variety of pre-built data integrations to access on-prem and cloud data sources. Developers and data engineers can directly utilize the connectors to integrate data on HyDDEP without developing any custom integrations.
          </p>
        </div>
      </div>
      <div class="row" data-aos="fade-up">
        <div class="row" data-aos="fade-up">
        <div class="col-md-7 order-1 order-md-2">
          <img src={Data_Image} class="img-fluid" alt="" />
        </div>
        <div class="col-md-5 order-2 order-md-1">

          <h3><strong>Unified Federated Data Image</strong></h3><br/><br />
          <p class="p-text standard-color">
            HyDDEP® enables building, navigating, and exploring a unified federated image of data entities across technologies.
          </p>
          <ul className='accordion-list p-text margin-top'>
            <li>Unify your enterprise and external data on a common federated data image leveraging HyDDEP® model based architecture</li>
            <li>Choose from pre-built enterprise models to unify your data</li>
            <li>Explore, navigate, and visualize your data model using HyDDEP®</li>
          </ul>

        </div>
      </div>

      <div class="row" data-aos="fade-up">
        <div class="col-md-7">
          <img src={Data_Lake} class="img-fluid" alt="" />
        </div>
        <div class="col-md-5">
        <h3><strong>Virtual Data Lake</strong></h3><br/><br />
          <p class="p-text standard-color">
            HyDDEP® enables data virtualization for organizations to leverage existing investments in data 
            stores and data lakes by unifying all data into a unified virtual data image, eliminating 
            the need to duplicate data.
          </p>
          <ul className='accordion-list p-text margin-top'>
            <li>Present a unified virtual layer of data from disparate sources and provide to users as if from a single source</li>
            <li>Create, update, or delete data for any object</li>
            <li>Invoke analytics to analyze and explore data on HyDDEP®</li>
            <li>Infer and expose metadata from source schemas</li>
          </ul>
        </div>
      </div>

      <div class="row" data-aos="fade-up">
        <div class="col-md-7 order-1 order-md-2">
          <img src={Data_connector} class="img-fluid" alt="" />
        </div>
        <div class="col-md-5 pt-5 order-2 order-md-1">
        <h3><strong>Data Connectors</strong></h3><br/><br />
          <p class="p-text standard-color">
            HyDDEP® provides a variety of data integrations to access cloud and on-premise data sources. 
            Developers and data engineers can directly utilize the connectors to integrate data on AIMLow® 
            without developing any custom integrations.
          </p>
          <ul className='accordion-list p-text margin-top'>
            <li>Databases and big-data stores including Snowflake, Impala, HBase, Postgres, Cosmos DB, MongoDB, Oracle, AWS RedShift, SQL Server</li>
            <li>Cloud applications including Salesforce, HubSpot</li>
            <li>Queue-based systems including Apache Kafka, Azure Event Hubs, Azure Topics, AWS Kinesis, AWS SQL</li>
            <li>File systems including AWS S3, Azure Data Lake Store gen2, Azure Blob, HDFS, local file system</li>
          </ul>
        </div>
      </div>

      <div class="row" data-aos="fade-up">
        <div class="col-md-7">
          <img src={Data_Object_model} class="img-fluid" alt="" />
        </div>
        <div class="col-md-5 pt-5">
        <h3><strong>Enterprise Object Models</strong></h3><br/><br />
          <p class="p-text standard-color">
            Dark Pools offers a variety of pre-built object models that can accelerate development of enterprise AI applications
          </p>
          <ul className='accordion-list p-text margin-top'>
            <li>Industry Object Models comprise predefined integration canonicals, data models, and time-series 
                for industries such as oil and gas, utilities, financial services, Logistics and supply-chain, 
                manufacturing, retail and More</li>
            <li>Functional Packages comprise supervised & unsupervised ML models, an ML interpretability framework, 
                and user interface tooling to address high-value use cases</li>
            <li>ML Packages contain methods for predictive analytics such as anomaly detection, NLP, object detection, and forecasting</li>
          </ul>
        </div>
      </div>
      </div>
    </div>
  </section>

  <section className="container-height section-bg" data-aos="fade-up" date-aos-delay="200">
    <div className="container">

      <div className="row">
        <h4 className="col-md-12 col-lg-12 demo-h4">Sign up for a Free Demo</h4>
      </div>

      <div className="row button-spacing">
        <button class="button-28" value="button">HyDDEP - Free Demo</button>
      </div>
    </div>
  </section>
  </main>
  </Fragment>
  )
}

export default HyddepPageProduct;