import React, { useState, Fragment } from "react";
import video from "../../assets/videos/neural-network-and-machine-learning-procedure.mp4";
import img1 from "../../../img/Data-Object-model.jpg";
import img2 from "../../../img/Link-analysis.png";
import img3 from "../../../img/Data-Lake.jpg";
import "./telecom-styles.css";
import "../styles.css";
import { keyAreaProducts } from "./key-areas";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { IconContext } from "react-icons";

const TelecommunicationProduct = () => {
  const [keyPoints, setKeyPoints] = useState(keyAreaProducts[0].keyIssues);
  return (
    <Fragment>
      <div className="product-sidebar">
        <ul className="sidebar-list">
          <li className="list-item">
            <a href="./Telecommunication" className="sidebar-link btn-active">
              Overview
            </a>
          </li>
          <li className="list-item">
            <a href="./TelecommunicationProducts" className="sidebar-link">
              Products
            </a>
          </li>
          <li className="list-item">
            <a href="./Customers" className="sidebar-link">
              Free Demo
            </a>
          </li>
        </ul>
      </div>

      <section
        id="hero"
        className="d-flex justify-content-center align-items-center"
      >
        <video autoPlay muted loop>
          <source src={video} type="video/mp4" />
        </video>
        <div
          id="heroCarousel"
          className="carousel carousel-fade container"
          data-ride="carousel"
        >
          <div className="carousel-item active">
            <div className="">
              <div></div>
              <h2 className="animated fadeInDown main-heading">
                <span>Dark Pools</span>
              </h2>
              <p className="animated fadeInUp main-text">
                Telecommunication Products
              </p>
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section className="about-section">
          <div className="block">
            <div className="content-section">
              <span id="overview-heading">Industry Problem</span>
              <p className="overview-text">
                Enforcement actions and penalties for non-compliance with AML
                regulations are on the rise. Banks are saddled with increasingly
                higher costs for transaction monitoring or facing the risks of
                hefty fines. At the same time bad actors are employing an
                increasingly diverse set of strategies to stay ahead of
                regulators and compliance departments. Current rules-based AML
                software creates a slew of cases that require extensive and
                manual investigation by compliance officers.
              </p>
            </div>
            <div className="content-section">
              <div className="text-box-left">
                <span id="overview-heading">AIMLow</span>
                <p className="overview-text">
                  Emulate the tasks of data scientists to automate the entire
                  predictive process. Predict what can happen and how likely it
                  is to happen and understand the variables affecting the
                  outcomes and display them in Analytical Charts types through
                  our dashboards.
                </p>
              </div>
              <div>
                <img src={img1} alt={"img1"} width="320px" />
              </div>
            </div>
            <div className="content-section">
              <div className="text-box-left">
                <img src={img2} alt={"img1"} width="320px" />
              </div>
              <div>
                <span id="overview-heading">NERoML</span>
                <p className="overview-text">
                  Combine geospatial and network datasets and dynamically
                  visualize it to compare coverage, existing infrastructure, and
                  plan future investments with drill-in and reporting features.
                  Apply models around activation, churn, RF propagation, and
                  network prioritization to improve network planning decisions
                  and scenario analysis.
                </p>
              </div>
            </div>
            <div className="content-section">
              <div className="text-box-left">
                <span id="overview-heading">HyDDEP</span>
                <p className="overview-text">
                  Complex data formed into diagrams and pictures that reveal
                  truths which aren’t immediately obvious. Merge huge amounts of
                  real-time data from numerous data sources into a single
                  picture allowing you to tap into critical information, reduce
                  redundant data and optimize efficiency which can be used to
                  improve business functions, processes and decision making.
                </p>
              </div>
              <div>
                <img src={img3} alt={"img1"} width="320px" />
              </div>
            </div>
          </div>
        </section>

        <section className="middle-section">
          <div className="block">
            <div className="product-panel-block">
              {keyAreaProducts.map((data, i) => (
                <span key={i} onClick={() => setKeyPoints(data.keyIssues)}>
                  {data.title}
                </span>
              ))}
            </div>
            <div className="telecom-panel-content">
              <div className="p-tag">
                <p>Key Issues</p>
              </div>
              <div className="key-issues-container">
                {keyPoints.map((keyIssue, i) => (
                  <div className="key-issues" key={i}>
                      <IconContext.Provider
                        value={{
                          color: "green",
                          className: "global-class-name",
                        }}
                      >
                          <IoMdCheckmarkCircleOutline />
                      </IconContext.Provider>
                    <span id="spacing">{keyIssue}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default TelecommunicationProduct;
