import { useState } from 'react'

const ContactForm_Investors = (props) => {
  return (
    <>
    <main id="main">
      <section className="breadcrumbs">
        <div className="container">
        </div>
      </section>

      <section className="ftco-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="title-size standard-color">Contact Other Enquiries</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="wrapper">
                <div className="row no-gutters">
                  <div className="col-lg-12 col-md-12 order-md-last d-flex align-items-stretch">
                    <div className="contact-wrap w-100 p-md-5 p-4">
                      <h3 className="mb-4">Get in touch</h3>
                      <div id="form-message-warning" className="mb-4"></div> 
                      <div id="form-message-success" className="mb-4">
                        Your message was sent, thank you!
                      </div>
                      <form method="POST" id="contactForm" name="contactForm" className="contactForm">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="label" for="name">Full Name</label>
                              <input type="text" className="form-control" name="name" id="name" placeholder="" />
                            </div>
                          </div>
                          <div className="col-md-6"> 
                            <div className="form-group">
                              <label className="label" for="email">Email Address</label>
                              <input type="email" className="form-control" name="email" id="email" placeholder="" />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="label" for="subject">Subject</label>
                              <input type="text" className="form-control" name="subject" id="subject" placeholder="" />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="label" for="#">Message</label>
                              <textarea name="message" className="form-control" id="message" cols="30" rows="4" placeholder=""></textarea>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <input type="submit" value="Send Message" className="button-28" />
                              <div className="submitting"></div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
	    </section>

      </main>
    </>
  )
}

export default ContactForm_Investors;