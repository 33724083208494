import logo from '../../img/dp-logo.png';
import Product from './product';
import Industries from './industries';
import { AiOutlineSearch } from 'react-icons/ai';
import { IconContext } from "react-icons";
import './style.css';

const Navigation = (props) => {
  return (
    <header className="header">
      <div className="logo">
        <a href="/"><img src={logo} alt={"logo"} width='40px'/></a>
      </div>
      <nav className="navbar-menu">
        <div className="wrapper">
          <ul>
            <li className="drop-down-link">
              <a href="#">Products</a>
              <Product />
            </li>
            <li className="drop-down-link">
              <a href="#">Industries</a>
              <Industries />
            </li>
            <li><a href="./Customers">Customer</a></li>
            <li className="drop-down-link">
              <a href="#">Resources</a>
            </li>
            <li className="drop-down-link">
              <a href="#">News</a>
            </li>
            <li className="drop-down-link">
              <a href="#">Company</a>
            </li>
          </ul>
        </div>
        <div className='icon-wrapper'>
          <IconContext.Provider value={{ color: "white", className: "global-class-name" }}>
            <div>
              <AiOutlineSearch  />
            </div>
          </IconContext.Provider>
          {/* <input type="text" placeholder="Search.." /> */}
        </div>
      </nav>
      <div className='contact-button-wrapper'>
        <div className="contact-button">
          <a href="./contactus" id='anchor-color'>Contact Us</a>
        </div>
      </div>
    </header>
  )
}

export default Navigation;
