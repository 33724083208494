import React from "react";
import HyDDEP from "../assets/images/Hyddep.png";
import AIMLow from "../assets/images/AIMLow.png";
import NERoML from "../assets/images/NERoML.png";
// import CRM from './../img/CRM.png'
// import X_machina from './../img/X-machina.png'
import './style.css';

const Product = () => {
  return (
    <ul className="unordered">
        <li className="list">
          <a href="./HyDDEP">
            <img 
            src={HyDDEP} 
            alt={"HyDDEP"}
            className="float-inDown"
            style={{width: "200px", animationDelay: "1s"}}
             />
            <p className="nav-p-tag float-inUp" style={{animationDelay: "1s"}}><strong>Hyper-Dimensional Data Enabled Platform</strong></p>
          </a>
        </li>
        <hr />
        <li className="list">
          <a href="./AIMLow">
            <img 
            src={AIMLow} 
            alt={"AIMLow"} 
            className="float-inDown"
            style={{width: "200px", animationDelay: "2s"}}
             />
            <p className="nav-p-tag float-inUp" style={{animationDelay: "2s"}}><strong>Artificial Intelligence | Machine Learning Operational Workflow</strong></p>
          </a>
        </li>
        <hr />
        <li className="list">
          <a href="./NERoML">
            <img 
            src={NERoML} 
            alt={"NERoML"} 
            className="float-inDown"
            style={{width: "200px", animationDelay: "3s"}} 
            />
            <p className="nav-p-tag float-inUp" style={{animationDelay: "3s"}}><strong>Network Ensemble Robust Machine Learning</strong></p>
          </a>
        </li>
    </ul>
  );
};

export default Product;
