import { useState, useEffect } from "react";
import Navigation from "./components/home-page/navigation";
import  Utilities_product_page  from "./components/industries/utilities_product_page";
import Footer from './components/footer-section/footer';
import "./App.css";

const UtilitiesProduct = () => {
  return (
    <div>
      <Navigation />
      <Utilities_product_page />
      <Footer />
    </div>
  );
};

export default UtilitiesProduct;
