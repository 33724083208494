export const keyAreaData = [
  {
    title: 'Equipment Readiness',
    keyIssues: ['Poor equipment availability', 'High cost of maintenance', 'Inefficiencies in parts inventory', 'Hard to track risk across thousands of assets', 'Loss of knowledge with aging workforce', ]
  },
  {
    title: 'Fraud, Waste, and Abuse',
    keyIssues: ['Complex fraud schemes', 'Costly and lengthy investigation process', 'High numbers of false alerts', 'Hard to prioritize investigations', 'Information silos across teams', ]
  },
  {
    title: 'Workforce Optimization',
    keyIssues: ['Disconnected data sources', 'Slow adjudication process', 'Difficult case prioritization', 'Lack of feedback loop to improve processes with data', ]
  },
  {
    title: 'Data and Analytics',
    keyIssues: ['Disparate, disconnected systems', 'Poor data quality', 'Lengthy, inefficient analytical process', 'Primitive data visualizations', 'Inefficient collaboration mechanisms' ]
  },
]

export const keyAreaProducts = [
  {
    title: 'Key Capabilities',
    keyIssues: ['Dashboard Module Analytics', 'Data Management and Exploration', 'Workflow Management toolkits', 'Anomaly Management toolkits', 'Fraud Alert and Case Management', 'Reporting', 'Recommendations and Insights']
  },
  {
    title: 'Use Cases',
    keyIssues: ['Anti-Money Laundering', 'Audit', 'Sanctions & Compliance', 'Transaction & Credit Card', 'Cyber & Malware', 'Law Enforcement']
  },
]