import { useState } from 'react'
import Customer_Header from './../img/Customer_Header.mp4';

const Customers = (props) => {
  return (
    <>
    
    <section id="hero" className="d-flex justify-content-center align-items-center">
      <video autoPlay muted loop>
        <source src={Customer_Header} type="video/mp4" />
      </video>
      <div id="heroCarousel" className="container carousel carousel-fade" data-ride="carousel">

        <div className="carousel-item active">
          <div className="carousel-container">
            <h2 className="animated fadeInDown"><span>Our Customers</span></h2>
          </div>
        </div>

      </div>
    </section>

    <main id="main">

      <section className="services">
        <div className="container container-margin">
          <div className="row">
            <div className="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up">
              <div className="icon-box">
                <h4 className="">Customer 1</h4>
                <p className="description">Customer Description</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
              <div className="icon-box icon-box-cyan">
                <h4 className="">Customer 2</h4>
                <p className="description">Customer Description</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
              <div className="icon-box icon-box-green">
                <h4 className="">Customer 3</h4>
                <p className="description">Customer Description</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
              <div className="icon-box icon-box-blue">
                <h4 className="">Customer 4</h4>
                <p className="description">Customer Description</p>
              </div>
            </div>

          </div>

        </div>
      </section>

    </main>
    </>   
  )
}

export default Customers;