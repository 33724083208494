import { useState, useEffect } from "react";
import Navigation from "./components/home-page/navigation";
import  Contact  from "./components/contactus";
import Footer from './components/footer-section/footer';
import "./App.css";

const ContactUs = () => {
  return (
    <div>
      <Navigation />
      <Contact />
      <Footer />
    </div>
  );
};

export default ContactUs;
