import React from "react";
import Navigation from "../components/home-page/navigation";
import RetailProductPage from "../components/industries/retail/retail_product_page";
import Footer from '../components/footer-section/footer';

const RetailProducts = () => {
  return (
    <div>
      <Navigation />
        <RetailProductPage />
      <Footer />
    </div>
  );
};

export default RetailProducts;
