import React, { useState, Fragment } from "react";
import video from "../../assets/videos/neural-network-and-machine-learning-procedure.mp4";
import image2 from "../../assets/images/industries/image2.png";
import "./telecom-styles.css";
import "../styles.css";
import { keyAreaData } from "./key-areas";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { IconContext } from "react-icons";

const TelecommunicationService = () => {
  const [keyPoints, setKeyPoints] = useState(keyAreaData[0].keyIssues);
  return (
    <Fragment>
      <div className="product-sidebar">
        <ul className="sidebar-list">
          <li className="list-item">
            <a href="./Telecommunication" className="sidebar-link btn-active">
              Overview
            </a>
          </li>
          <li className="list-item">
            <a href="./TelecommunicationProducts" className="sidebar-link">
              Products
            </a>
          </li>
          <li className="list-item">
            <a href="./Customers" className="sidebar-link">
              Free Demo
            </a>
          </li>
        </ul>
      </div>

      <section
        id="hero"
        className="d-flex justify-content-center align-items-center"
      >
        <video autoPlay muted loop>
          <source src={video} type="video/mp4" />
        </video>
        <div
          id="heroCarousel"
          className="carousel carousel-fade container"
          data-ride="carousel"
        >
          <div className="carousel-item active">
            <div className="">
              <div></div>
              <h2 className="animated fadeInDown main-heading">
                <span>Dark Pools</span>
              </h2>
              <p className="animated fadeInUp main-text">
                Telecommunication Services
              </p>
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section className="about-section">
          <div className="block">
            <span id="overview-heading">
              Dark Pools Fueling Innovation Across the Telecommunication Value
              Chain
            </span>
            <div className="inside-content-wrapper">
              <p className="overview-text">
                Enabling the telecommunication industry's dynamic capabilities to
                extract valuable business insights. Telecoms have a massive amount
                of Big Data, Dark Pools AI solutions can use it to make efficient
                and effective decisions through customer segmentation, predicting
                the lifetime value of a consumer, and making purchase
                recommendations.
              </p>
              <div className="image-wrapper">
                <img src={image2} alt={image2} className="main-images"/>
              </div>
            </div>
          </div>
        </section>

        <section className="middle-section">
          <div className="block">
            <div className="new-panel-block">
              {keyAreaData.map((data, i) => (
                <span key={i} onClick={() => setKeyPoints(data.keyIssues)}>
                  {data.title}
                </span>
              ))}
            </div>
            <div className="telecom-panel-content">
              <div className="p-tag">
                <p>Key Issues</p>
              </div>
              <div className="key-issues-container">
                {keyPoints.map((keyIssue, i) => (
                  <div className="key-issues" key={i}>
                    <IconContext.Provider
                      value={{
                        color: "green",
                        className: "global-class-name",
                      }}
                    >
                      <IoMdCheckmarkCircleOutline />
                    </IconContext.Provider>
                    <span id="spacing">{keyIssue}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default TelecommunicationService;
