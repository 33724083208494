import React from "react";
import Navigation from "./components/home-page/navigation";
import HyddepPage  from "./components/products/hyddep-page/HyddepPage";
import Footer from './components/footer-section/footer';
import "./App.css";

const HyDDEP = () => {
  return (
    <div>
      <Navigation />
      <HyddepPage />
      <Footer />
    </div>
  );
};

export default HyDDEP;
