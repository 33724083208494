import React from "react";
import video from "../../img/header.mp4";
import AIMLow_Header from "../../img/Resources_Header.mp4";

const Home = () => {
  return (
    <>
      <section
        id="hero"
        className="d-flex justify-content-center align-items-center"
      >
        <video autoPlay muted loop>
          <source src={AIMLow_Header} type="video/mp4" />
        </video>
        <div
          id="heroCarousel"
          className="container carousel carousel-fade"
          data-ride="carousel"
        >
          <div className="carousel-item active">
            <div className="carousel-container">
              <h2 className="animated fadeInDown">
                <span className="logo-heading">Dark Pools</span>
              </h2>
              <p className="logo-text animated fadeInUp">
                AI DRIVEN REAL-TIME FINANCIAL CRIMES DETECTION SOFTWARE
              </p>
            </div>
          </div>

          <div className="carousel-item">
            <div className="carousel-container">
              <h2 className="animated fadeInDown">
                <span className="logo-heading">Dark Pools</span>
              </h2>
              <p className="logo-text animated fadeInUp">
                AI DRIVEN REAL-TIME FINANCIAL CRIMES DETECTION SOFTWARE
              </p>
            </div>
          </div>
        </div>
      </section>
      <main id="main">
        <section className="services">
          <div className="container">
            <p className="home-page-content">
              Dark Pool's leadership and expertise in automated machine learning
              is helping Industries and markets around the world effectively
              meet industry requirements while simultaneously delivering high
              value smart solutions that increase revenue, optimise operations,
              mitigate risk and personalize customer experiences and a variety
              of customizable anomaly detection.
            </p>
            <p className="home-page-content">
              Dark Pools orchestration enables intelligence driven automation,
              acceleration and transparency through every step of the data
              science lifecycle. It also provides companies with a completely
              flexible architecture specifically design around your Industry
              Business Ontology (IBO) through an extensible platform that scales
              to meet the complexity of service use cases.
            </p>
            <div className="heading-container">
              <h2 className="services-heading float-inUp">
                Take the next step
              </h2>
            </div>
            <div className="form_row">
              <a href="./ContactForm_Sales" >
                <div className="item-box-form" data-aos="fade-up">
                  <h4 className="title_of_form_link">Contact Sales</h4>
                  <p className="description_of_form_link">
                    Learn how our industry leading Enterprise software products
                    can help your organization.
                  </p>
                </div>
              </a>
              <a href="./ContactForm_Alliances">
                <div
                  className="item-box-form"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <h4 className="title_of_form_link">Contact Alliances</h4>
                  <p className="description_of_form_link">
                    Interested in becoming a Dark Pools partner? Contact our
                    Alliances team.
                  </p>
                </div>
              </a>
              <a href="./ContactForm_Investors">
                <div
                  className="item-box-form"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <h4 className="title_of_form_link">Investor Relation</h4>
                  <p className="description_of_form_link">
                    Contact us at (investors email address) to learn more about
                    investing in Dark Pools.
                  </p>
                </div>
              </a>
              <a href="./ContactForm_Others">
                <div
                  className="item-box-form"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <h4 className="title_of_form_link">Request Information</h4>
                  <p className="description_of_form_link">
                    For all other questions, please contact us here.
                  </p>
                </div>
              </a>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Home;
