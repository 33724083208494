import React, { Fragment } from 'react';
import HyDDEP_Header from '../../../img/HyDDEP_Header.mp4';
import HyDDEP_image from '../../assets/images/Hyddep.png';
import FreeDemoForm from '../FreeDemoForm';
import "../styles.css";

const HyddepPage = () => {
  return (
    <Fragment>
    <div className="product-sidebar">
      <ul className="sidebar-list">
        <li className="list-item"><a href="./HyDDEP" className='sidebar-link btn-active'>Overview</a></li>
        <li className="list-item"><a href="./HyDDEP_products" className='sidebar-link'>Product</a></li>
        <li className="list-item"><a href="./NERoML_freedemo" className='sidebar-link'>Free Demo</a></li>
      </ul>
    </div>

  <section id="hero" className="d-flex justify-content-center align-items-center">
    <video autoPlay muted loop>
      <source src={HyDDEP_Header} type="video/mp4" />
    </video>
    <div id="heroCarousel" className="container carousel carousel-fade" data-ride="carousel">

    <div className="carousel-item active">
      <div className="carousel-container">
        <img src={HyDDEP_image} alt={HyDDEP_image} className="main-logo"/>
      </div>
    </div>
      <FreeDemoForm />
    </div>
  </section>

  <main id="main">
    <section className="about" data-aos="fade-up">
      <div className="container container-margin">
        <div className="row">
          <div className="col-lg-12 pt-4 pt-lg-0">
            <p className="text-justify title-p standard-color">
              HyDDEP® is a one stop platform for data integration, ingesting data from multiple data sources to 
              create unified sets of information for both operational and analytical uses for better exploration. 
              HyDDEP ® produces consolidated data sets that are clean and consistent and meet the requirements of 
              different use cases.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="features">
      <div class="container container-margin">
        <div class="row" data-aos="fade-up">
          <div class="col-md-12">
            {/* <img src={Graph_time_based} class="img-fluid" alt={Graph_time_based} /> */}
            <h1>Add Image here</h1>
          </div>
        </div>
      </div>
    </section>
    
  </main>
  </Fragment>
  )
}

export default HyddepPage;