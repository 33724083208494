import React, { Fragment } from 'react';
import sampleImage from './../../img/sample.jpg';
import Indutries_Header from './../../img/Indutries_Header.mp4';
import "./styles.css";

const Utilities = (props) => {
  return (
    <Fragment>
      <div className="product-sidebar">
        <ul className="sidebar-list">
          <li className="list-item"><a href="./Utilities" className='sidebar-link btn-active'>Overview</a></li>
          <li className="list-item"><a href="./UtilitiesProduct" className='sidebar-link'>Products</a></li>
          <li className="list-item"><a href="./Customers" className='sidebar-link'>Customer</a></li>
          <li className="list-item"><a href="./Resources" className='sidebar-link'>Resources</a></li>
        </ul>
      </div>  

    <section id="hero" className="d-flex justify-cntent-center align-items-center">
      <video autoPlay muted loop>
        <source src={Indutries_Header} type="video/mp4" />
      </video>
      <div id="heroCarousel" className="container carousel carousel-fade" data-ride="carousel">

        <div className="carousel-item active">
          <div className="">
            <div ></div>
            <h2 className="animated fadeInDown"><span>Dark Pools for</span></h2>
            <p className="animated fadeInUp main-text">Utilities</p>
          </div>
        </div>

      </div>
    </section>

    <main id="main">

      <section className="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 pt-4 pt-lg-0">
              <h3>Dark Pools AI Addresses Key Issues Across the Utilities Value Chain</h3><br/><br />
              <p className="color-white">
                NERoML® is a powerful and pre-integrated Enterprise AI platform that enables global manufacturers to 
                rapidly integrate data from a variety of sources, including internal enterprise applications, sensor 
                networks, operational systems, and exogenous third party data to power AI models. This platform and 
                unified data is the foundation of  NERoML® AI’s SaaS applications to address inventory optimization, 
                predictive maintenance, production schedule optimization, and other high-impact use cases. These are 
                tried, tested, and proven Enterprise AI applications that can be quickly customized and deployed in 
                1-2 quarters, scaled across global manufacturing and supply chain operations, and yield business value 
                measured in 100s of millions annually.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="acc">
                <div className="panel">
                    <span data-toggle="collapse" data-parent=".acc" data-target="#Serve" className="accordion-btn btn-default">Serve Customers</span>
                    <span data-toggle="collapse" data-parent=".acc" data-target="#Transport" className="accordion-btn btn-default">Smart Meters</span>
                    <span data-toggle="collapse" data-parent=".acc" data-target="#Manufacture" className="accordion-btn btn-default">Transmission and Distribution</span>
                    <span data-toggle="collapse" data-parent=".acc" data-target="#Manage" className="accordion-btn btn-default">Power Generation</span>

                    <div className="clearfix"></div>

                    <div className="collapse accordion-content" id="Serve">
                      <div className="row">
                        <div className="col-md-6">
                        <h4 className="" >Key Issues </h4>
                        <div className="line-separator"></div>
                          <ul className="accordion-list">
                            <li>Difficulty in forecasting energy demand</li>
                            <li>Low customer engagement in energy efficiency programs</li>
                            <li>Lack of personalized energy insights and promotions</li>
                            <li>Communication during outages and emergencies</li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                            <h4 className="" >AIMLow® Solutions </h4>
                            <div className="line-separator-fullwidth"></div>
                          <div className="col-md-6">
                            <i className="icofont-check-circled"></i>
                            <h4 className="title font-weight" >AIMLow® CRM </h4>
                            <p className="standard-color">Next gen CRM designed for AI/ML</p>
                          </div>
                          <div className="col-md-6">
                            <i className="icofont-check-circled"></i>
                            <h4 className="title font-weight" >AIMLow® Energy Management</h4>
                            <p className="standard-color">Reduce energy costs and improve building operations</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="collapse accordion-content" id="Transport">
                      <div className="row">
                        <div className="col-md-6">
                        <h4 className="" >Key Issues </h4>
                        <div className="line-separator"></div>
                          <ul className="accordion-list">
                            <li>Poor visibility into real-time operations</li>
                            <li>Reactive failure analysis</li>
                            <li>Installation errors</li>
                            <li>Energy theft</li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                            <h4 className="" >AIMLow® Solutions</h4>
                            <div className="line-separator-fullwidth"></div>
                          <div className="col-md-6">
                            <i className="icofont-check-circled"></i>
                            <h4 className="title font-weight" >AIMLow® AMI Operations </h4>
                            <p className="standard-color">Ensure the optimal deployment and operational health of AMI asset</p>
                          </div>
                          <div className="col-md-6">
                            <i className="icofont-check-circled"></i>
                            <h4 className="title font-weight" >AIMLow® Fraud Detection </h4>
                            <p className="standard-color">Manage and mitigate emergent product issues</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="collapse accordion-content" id="Manufacture">
                      <div className="row">
                        <div className="col-md-6">
                        <h4 className="" >Key Issues </h4>
                        <div className="line-separator"></div>
                          <ul className="accordion-list">
                            <li>Safety and failure risks</li>
                            <li>Aging assets</li>
                            <li>Multiple points of failure</li>
                            <li>Disparate data systems</li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                            <h4 className="" >AIMLow® Solution </h4>
                            <div className="line-separator-fullwidth"></div>
                          <div className="col-md-6">
                            <i className="icofont-check-circled"></i>
                            <h4 className="title font-weight" >AIMLow® Reliability </h4>
                            <p className="standard-color">Prioritize and optimize asset maintenance and planning</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="collapse accordion-content" id="Manage">
                      <div className="row">
                        <div className="col-md-6">
                        <h4 className="" >Key Issues </h4>
                        <div className="line-separator"></div>
                          <ul className="accordion-list">
                            <li>Changing energy mix</li>
                            <li>Lack of energy system flexibility</li>
                            <li>Aging fossil fuel-based assets</li>
                            <li>Safety and failure risk</li>
                            <li>Loss of institutional knowledge</li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                            <h4 className="" >AIMLow® Solutions</h4>
                            <div className="line-separator-fullwidth"></div>
                          <div className="col-md-6">
                            <i className="icofont-check-circled"></i>
                            <h4 className="title font-weight" >AIMLow® Reliability </h4>
                            <p className="standard-color">Prioritize and optimize asset maintenance and planning</p>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    </Fragment>   
  )
}

export default Utilities;