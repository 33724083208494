import React, { Fragment } from "react";
import "./styles.css";

const Footer = () => {
  return (
    <Fragment>
    <footer id="footer">
      <div className="footer-top">
      <div className="container">
          <div className="footer-solution-content">
            <div className="footer-links-section">
              <h4>Company</h4> 
              <ul>
                <li className="footer-link"><p className='sparkle u-hover--sparkle'><a href="#">Home</a></p></li>
                <li className="footer-link"><p className='sparkle u-hover--sparkle'><a href="#">About us</a></p></li>
                <li className="footer-link"><p className='sparkle u-hover--sparkle'><a href="#">Services</a></p></li>
                <li className="footer-link"><p className='sparkle u-hover--sparkle'><a href="#">Terms of service</a></p></li>
                <li className="footer-link"><p className='sparkle u-hover--sparkle'><a href="#">Privacy policy</a></p></li>
              </ul>
            </div>

            <div className="footer-links-section">
              <h4>Products</h4> 
              <ul>
                <li className="footer-link"><p className='sparkle u-hover--sparkle'><a href="#">HyDDEP</a></p></li>
                <li className="footer-link"><p className='sparkle u-hover--sparkle'><a href="#">AIMLow</a></p></li>
                <li className="footer-link"><p className='sparkle u-hover--sparkle'><a href="#">NERoML</a></p></li>
              </ul>
            </div>

            <div className="footer-links-section ">
              <h4>Industries</h4>
              <ul>
                <li className="footer-link"><p className='sparkle u-hover--sparkle'><a href="#">Financial Services</a></p></li>
                <li className="footer-link"><p className='sparkle u-hover--sparkle'><a href="#">Government</a></p></li>
                <li className="footer-link"><p className='sparkle u-hover--sparkle'><a href="#">Retail</a></p></li>
                <li className="footer-link"><p className='sparkle u-hover--sparkle'><a href="#">Telecommunication</a></p></li>
              </ul>
            </div>

            <div className="footer-links-section ">
              <h4>Help</h4>
              <ul>
                <li className="footer-link"><p className='sparkle u-hover--sparkle'><a href="#">Financial Services</a></p></li>
                <li className="footer-link"><p className='sparkle u-hover--sparkle'><a href="#">Government</a></p></li>
                <li className="footer-link"><p className='sparkle u-hover--sparkle'><a href="#">Retail</a></p></li>
                <li className="footer-link"><p className='sparkle u-hover--sparkle'><a href="#">Telecommunication</a></p></li>
              </ul>
            </div>

            <div className="footer-links-section ">
              <h4>Other</h4>
              <ul>
                <li className="footer-link"><p className='sparkle u-hover--sparkle'><a href="#">Financial Services</a></p></li>
                <li className="footer-link"><p className='sparkle u-hover--sparkle'><a href="#">Government</a></p></li>
                <li className="footer-link"><p className='sparkle u-hover--sparkle'><a href="#">Retail</a></p></li>
                <li className="footer-link"><p className='sparkle u-hover--sparkle'><a href="#">Telecommunication</a></p></li>
              </ul>
            </div>
            
          </div>
            {/* <div className='media-container'>
                <h5>GET SOCIAL</h5>
                <p>Follow us and be the first to hear about exciting new products & promotions.</p>
                <div className="social-media-links">
                  <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
                  <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
                  <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
                  <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                  </div>
            </div> */}
      </div>

        <div className="copyright">
          2022 &copy; Copyright <strong><span>Dark Pools</span></strong>. All Rights Reserved
        </div>
      </div>
    </footer><a href="#" className="back-to-top"><i className="icofont-simple-up"></i></a>
    </Fragment>
  )
}

export default Footer;