import React, { Fragment } from 'react';
import sampleImage from './../../img/sample.jpg';
import Indutries_Header from './../../img/Indutries_Header.mp4';
import "./styles.css";

const Manufacturing = () => {
  return (
    <>
      <div className="product-sidebar">
        <ul className="sidebar-list">
          <li className="list-item"><a href="./Manufacturing" className='sidebar-link btn-active'>Overview</a></li>
          <li className="list-item"><a href="./ManufacturingProduct" className='sidebar-link'>Products</a></li>
          <li className="list-item"><a href="./Customers" className='sidebar-link'>Customer</a></li>
          <li className="list-item"><a href="./Resources" className='sidebar-link'>Resources</a></li>
        </ul>
      </div>  

    <section id="hero" className="d-flex justify-cntent-center align-items-center">
      <video autoPlay muted loop>
        <source src={Indutries_Header} type="video/mp4" />
      </video>
      <div id="heroCarousel" className="container carousel carousel-fade" data-ride="carousel">

        <div className="carousel-item active">
          <div className="">
            <div ></div>
            <h2 className="animated fadeInDown"><span>Dark Pools for</span></h2>
            <p className="animated fadeInUp main-text">Manufacturing Product</p>
          </div>
        </div>

      </div>
    </section>

    <main id="main">

      <section className="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 title-size">
              <h3>AIMLow® Inventory Optimization</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h3>Industry Problem</h3><br/><br />
              <p className="color-white">
                Visibility into inventory part supply and product performance is something that manufacturers 
                continually struggle with. Whether the challenge is maintaining part supply for highly customized 
                products or forecasting customer demand during unprecedented circumstances, the need to have a real-time 
                view into inventory, the ability to model optimization schemes given different scenarios, and the ability 
                to predict order lead time is critical for meeting customer demand while remaining profitable.
              </p>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h3>AIMLow® Solution </h3><br/><br />
              <ul className="accordion-list">
                <li>Reduce inventory and associated inventory holding costs by optimizing reorder parameters.</li>
                <li>Quantify and account for critical uncertainties such as likelihood of supplier delay or quality 
                    issues, seasonality, and production-line disruptions</li>
                <li>Enhance organizational efficiency by establishing a common operating view of the supply 
                    chain across all relevant departments</li>
                <li>Improve productivity of inventory analysts through automated recommendations based on new data and 
                    live integration with operational systems</li>
                <li>Minimize total landed costs that include standard and expedited shipping costs as a result of 
                    reduced inventory in the supply chain</li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 pt-4 pt-lg-0">
              <div className="link-panel">
                <a href="#" className="" >Learn More</a> | <a href="#" className="">Free Demo</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 title-size">
              <h3>AIMLow® Supply Network Risk</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h3>Industry Problem</h3><br/><br />
              <p className="color-white">
               Manufacturers today have to manage increasingly complex and global supply chains. Disruption in 
               raw material supply, transportation, and manufacturing disruptions can cause a ripple effect and 
               severely impact on-time delivery of committed production. Lack of visibility into these supply 
               chain risks is detrimental to forecasting and product planning.
              </p>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h3>AIMLow® Solution </h3><br/><br />
              <ul className="accordion-list">
                <li>Monitor supply risk at the global, regional, and facility level</li>
                <li>Avoid production shortages by predicting and remediating delay risks</li>
                <li>Minimize expedited shipping costs by predicting and addressing delays well in advance of target delivery dates</li>
                <li>Manage supplier relationships armed with data on supplier performance</li>
                <li>Improve capacity planning based on an in-depth understanding of supply chain performance and associated risks</li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 pt-4 pt-lg-0">
              <div className="link-panel">
                <a href="#" className="" >Learn More</a> | <a href="#" className="">Free Demo</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 title-size">
              <h3>AIMLow® Production Schedule Optimization</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h3>Industry Problem</h3><br/><br />
              <p className="color-white">
                Production Managers and Planning Engineers struggle to effectively optimize production plans in 
                constrained and uncertain environments, in turn impacting an organization's ability to deliver 
                orders on-time and in-full and meet customer demands
              </p>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h3>AIMLow® Solution </h3><br/><br />
              <ul className="accordion-list">
                <li>Use an elastic solver to plan production tasks and activities for hundreds of thousands of customer-product pairings</li>
                <li>Use AI-based production schedule optimization to maximize throughput and minimize waste</li>
                <li>Use machine learning algorithms trained on historical data to predict change orders and guide demand forecasting process</li>
                <li>Predict orders most likely to be modified using AI and update planning and scheduling accordingly</li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 pt-4 pt-lg-0">
              <div className="link-panel">
                <a href="#" className="" >Learn More</a> | <a href="#" className="">Free Demo</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 title-size">
              <h3>AIMLow® Process Optimization</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h3>Industry Problem</h3><br/><br />
              <p className="color-white">
                Manufacturers lose production capacity and incur substantial costs due to sub-standard raw materials 
                and quality defects. When caught early in the manufacturing process, remedial actions are easier to take, 
                and waste can be mitigated.
              </p>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h3>AIMLow® Solution </h3><br/><br />
              <ul className="accordion-list">
                <li>Improve product quality and overall yield across batch and continuous processes using AI to predict 
                  off-spec product more efficiently than with traditional methods</li>
                <li>Use ML models to identify product yield risk drivers and generate contextualized alerts and 
                    actionable insights to drive interventions</li>
                <li>Eliminate or reduce wait times for sample testing by using robust prediction algorithms instead</li>
                <li>Remove sources of human and instrument error</li>
                <li>Use a unified image of all process and operational data to understand and remediate issues at a system level</li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 pt-4 pt-lg-0">
              <div className="link-panel">
                <a href="#" className="" >Learn More</a> | <a href="#" className="">Free Demo</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 title-size">
              <h3>AIMLow® Reliability </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h3>Industry Problem</h3><br/><br />
              <p className="color-white">
                Maintenance planners and equipment operators lack the ability to effectively understand manufacturing 
                equipment risk and prioritize maintenance activity and expenditures. This can lead to significant 
                unplanned downtime, costing companies millions in both expense and lost production capacity.
              </p>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h3>AIMLow® Solution </h3><br/><br />
              <ul className="accordion-list">
                <li>Reduce or eliminate unplanned failures through AI-based early identification and prioritization of high-risk conditions</li>
                <li>Maximize equipment life and reduce replacement costs by avoiding catastrophic failure</li>
                <li>Improve maintenance planning and reduce maintenance cost by scheduling maintenance based on equipment condition</li>
                <li>Minimize equipment redundancy by operating without unplanned failure</li>
                <li>Reduce parts inventory by ensuring the right maintenance parts are in the right places at the right time</li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 pt-4 pt-lg-0">
              <div className="link-panel">
                <a href="#" className="" >Learn More</a> | <a href="#" className="">Free Demo</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 title-size">
              <h3>AIMLow® CRM</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h3>Industry Problem</h3><br/><br />
              <p className="color-white">
                Traditional general-purpose CRM applications are no longer sufficient to address the dynamics of 
                today's fast-moving global markets and the mandates of digital transformation. A new AI-first CRM is 
                required to boost performance of sales, marketing and services in the manufacturing industry.
              </p>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h3>AIMLow® Solution </h3><br/><br />
              <ul className="accordion-list">
                <li>Expand data inputs beyond traditional CRM to all enterprise data (e.g. supply chain) and extraprise data (e.g. economic)</li>
                <li>Apply AI to improve forecast accuracy, analyse unstructured data, determine probability of closure and identify areas of risk to improve the forecast</li>
                <li>Customer Churn Management</li>
                <li>Next-best-offer</li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 pt-4 pt-lg-0">
              <div className="link-panel">
                <a href="#" className="" >Learn More</a> | <a href="#" className="">Free Demo</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 title-size">
              <h3>AIMLow® Energy Management</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h3>Industry Problem</h3><br/><br />
              <p className="color-white">
               Manufacturing operations can be quite energy intensive. Manufacturing facilities alone are responsible 
               for spending over 200 billion every year to power facilities and waste nearly 30 percent of that energy. 
               There can be various causes of energy waste in manufacturing including leaky air compressors, inefficient 
               equipment, and unnecessary heating/cooling in buildings. Energy waste combined with unanticipated demand 
               can lead to conflated energy costs and be a drag on profitability
              </p>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h3>AIMLow® Solution </h3><br/><br />
              <ul className="accordion-list">
                <li>Reduce energy expenditures using predictive analytics to identify high-value savings opportunities</li>
                <li>Forecast consumption and predict peak demand events with tailored machine learning algorithms that provide high degrees of accuracy</li>
                <li>Monitor asset health and equipment efficiency in real time to improve system reliability and manage costs</li>
                <li>Optimize cost- and carbon-saving investments with machine learning insights and 'what if' scenario analysis</li>
                <li>Achieve sustainability goals with tailored carbon-reduction insights, AI-powered measurement & verification, and streamlined financial and carbon emissions reporting</li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 pt-4 pt-lg-0">
              <div className="link-panel">
                <a href="#" className="" >Learn More</a> | <a href="#" className="">Free Demo</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      {/* <section className="about" data-aos="fade-up">
        <div className="container">
          <div className="row padding-height">
            <div class="col-md-12">
              <h3 className="title-size" >Successful Deployments</h3>
            </div>
          </div>
          <div className="row">
          <div class="col-md-7 order-1 order-md-2">
            <img src={sampleImage} class="img-fluid" alt="" />
          </div>
          <div class="col-md-5 pt-5 order-2 order-md-1">
            <h3>Optimizing Inventory Levels for a $30 Billion Global Discrete Manufacturer</h3>
            <p class="color-white">
              Enhanced part-level demand forecasting accuracy and dynamic inventory optimization.
            </p>
            <button class="button-28" role="button">Learn More</button>
          </div>
          </div>
        </div>
      </section>

      <section class="team section-bgsecondary">
      <div class="container">
        <div className="row">
          <h4 className="col-md-6 col-lg-6 title-size">Related Resources</h4>
        </div>
        <div class="row">

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div class="member boxing-style">
              <div class="member-img">
                <img src={sampleImage} class="img-fluid" alt="" />
              </div>
              <div class="member-info">
                <h4>10 Core Principles</h4>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div class="member boxing-style">
              <div class="member-img">
                <img src={sampleImage} class="img-fluid" alt="" />
              </div>
              <div class="member-info">
                <h4>The Live Product Update</h4>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div class="member boxing-style">
              <div class="member-img">
                <img src={sampleImage} class="img-fluid" alt="" />
              </div>
              <div class="member-info">
                <h4>The Principles of Enterprise AI Livestream</h4>
              </div>
            </div>
          </div>
        </div>

      </div>
      </section> */}

    </main>
    </> 
  )
}

export default Manufacturing;