import React, { Fragment } from 'react';
import Indutries_Header from './../../img/Indutries_Header.mp4';
import "./styles.css";

const OilAndGas = (props) => {
  return (
    <Fragment>
    <section id="hero" className="d-flex justify-cntent-center align-items-center">
      <video autoPlay muted loop>
        <source src={Indutries_Header} type="video/mp4" />
      </video>
      <div id="heroCarousel" className="container carousel carousel-fade" data-ride="carousel">

        <div className="carousel-item active">
          <div className="">
            <div ></div>
            <h4 className="animated fadeInDown"><span>Enterprise AI for</span></h4>
            <p className="animated fadeInUp text-left-align">Oil & Gas</p>
          </div>
        </div>

      </div>
    </section>

    <main id="main">
      <section className="services">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 d-flex align-items-stretch">
              <div className="icon-box-pink">
                <h4 className="h4-title">Add Video here</h4>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div className="icon-box-cyan">
                <h4 className="h4-title">AI Addresses Key Issues Across the Oil and Gas Value Chain</h4><br /><br />
                <p className="description">BakerHughesC3.ai is a strategic alliance that brings together the energy technology expertise and comprehensive portfolio of Baker Hughes with C3 AI’s unique AI software to enable digital transformation of the oil and gas industry. We believe in the power of Enterprise AI to deliver predictive intelligence that helps oil and gas businesses deliver safer, cleaner, and more sustainable energy. The BHC3 strategic alliance delivers the industry-leading BHC3™ AI Application Platform, a platform to build, deploy, and operate enterprise-scale AI applications 18x to 100x faster than alternative approaches. The BHC3 AI Application Platform supports the oil and gas value chain with a collection of pre-built AI applications for reliability, production optimization, inventory management, and sustainable operations.</p><br /><br />
                <p>Learn how Baker Hughes and C3 AI are solving Enterprise AI challenges in the oil & gas sector​ </p>
                <button class="button-28" role="button">Visit Website</button>
              </div>
            </div>

          </div>

        </div>
      </section>
    </main>
    </Fragment>   
  )
}

export default OilAndGas;