import React, { Fragment } from 'react';
import sampleImage from './../../img/sample.jpg';
import Indutries_Header from './../../img/Indutries_Header.mp4';
import "./styles.css";

const Manufacturing = (props) => {
  return (
    <Fragment>
      <div className="product-sidebar">
        <ul className="sidebar-list">
          <li className="list-item"><a href="./Manufacturing" className='sidebar-link btn-active'>Overview</a></li>
          <li className="list-item"><a href="./ManufacturingProduct" className='sidebar-link'>Products</a></li>
          <li className="list-item"><a href="./Customers" className='sidebar-link'>Customer</a></li>
          <li className="list-item"><a href="./Resources" className='sidebar-link'>Resources</a></li>
        </ul>
      </div>  

    <section id="hero" className="d-flex justify-cntent-center align-items-center">
      <video autoPlay muted loop>
        <source src={Indutries_Header} type="video/mp4" />
      </video>
      <div id="heroCarousel" className="container carousel carousel-fade" data-ride="carousel">

        <div className="carousel-item active">
          <div className="">
            <div ></div>
            <h2 className="animated fadeInDown"><span>Dark Pools for</span></h2>
            <p className="animated fadeInUp main-text">Manufacturing</p>
          </div>
        </div>

      </div>
    </section>

    <main id="main">

      <section className="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 pt-4 pt-lg-0">
              <h3>Dark Pools AI Addresses Key Issues Across the Manufacturing Value Chain</h3><br/><br />
              <p className="color-white">
                NERoML® is a powerful and pre-integrated Enterprise AI platform that enables global manufacturers 
                to rapidly integrate data from a variety of sources, including internal enterprise applications, 
                sensor networks, operational systems, and exogenous third party data to power AI models. 
                This platform and unified data is the foundation of  NERoML® AI's SaaS applications to address 
                inventory optimization, predictive maintenance, production schedule optimization, and other high-impact 
                use cases. These are tried, tested, and proven Enterprise AI applications that can be quickly customized 
                and deployed in 1-2 quarters, scaled across global manufacturing and supply chain operations, and yield 
                business value measured in 100s of millions annually.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="acc">
                <div className="panel">
                    <span data-toggle="collapse" data-parent=".acc" data-target="#Serve" className="accordion-btn">Serve Customers</span>
                    <span data-toggle="collapse" data-parent=".acc" data-target="#Transport" className="accordion-btn">Transport & Distribution</span>
                    <span data-toggle="collapse" data-parent=".acc" data-target="#Manufacture" className="accordion-btn">Manufacture</span>
                    <span data-toggle="collapse" data-parent=".acc" data-target="#Manage" className="accordion-btn">Manage Supply</span>

                    <div className="clearfix"></div>

                    <div className="collapse accordion-content" id="Serve">
                      <div className="row">
                        <div className="col-md-6">
                        <h4 className="" >Key Issues </h4>
                        <div className="line-separator"></div>
                          <ul className="accordion-list">
                            <li>Inaccurate revenue and product forecasts</li>
                            <li>Poor opportunity prioritization</li>
                            <li>Excess price discounts</li>
                            <li>High churn rates</li>
                            <li>Low revenue per customer</li>
                            <li>Poor sales productivity</li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                            <h4 className="" >AIMLow® Solutions </h4>
                            <div className="line-separator-fullwidth"></div>
                          <div className="col-md-6">
                            <i className="icofont-check-circled"></i>
                            <h4 className="title font-weight" >AIMLow® CRM </h4>
                            <p className="standard-color">Next gen CRM designed for AI/ML</p>
                          </div>
                          <div className="col-md-6">
                            <i className="icofont-check-circled"></i>
                            <h4 className="title font-weight" >AIMLow® Energy Management </h4>
                            <p className="standard-color">Reduce energy costs and improve building operations</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="collapse accordion-content" id="Transport">
                      <div className="row">
                        <div className="col-md-6">
                        <h4 className="" >Key Issues </h4>
                        <div className="line-separator"></div>
                          <ul className="accordion-list">
                            <li>Uncertain demand</li>
                            <li>Poor inventory visibility</li>
                            <li>High inventory holding costs</li>
                            <li>Poor OTIF performance</li>
                            <li>Sub-optimal re-order parameters</li>
                            <li>Expedited shipping costs</li>
                            <li>Non-competitive delivery times</li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                            <h4 className="" >AIMLow® Solutions</h4>
                            <div className="line-separator-fullwidth"></div>
                          <div className="col-md-6">
                            <i className="icofont-check-circled"></i>
                            <h4 className="title font-weight" >AIMLow® Inventory Optimization </h4>
                            <p className="standard-color">Reduce inventory costs with confidence</p>
                          </div>
                          <div className="col-md-6">
                            <i className="icofont-check-circled"></i>
                            <h4 className="title font-weight" >AIMLow® Supply Network Risk </h4>
                            <p className="standard-color">Predict disruptions in delivery times and quality</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="collapse accordion-content" id="Manufacture">
                      <div className="row">
                        <div className="col-md-6">
                        <h4 className="" >Key Issues </h4>
                        <div className="line-separator"></div>
                          <ul className="accordion-list">
                            <li>Equipment downtime</li>
                            <li>Process anomalies</li>
                            <li>Quality defects</li>
                            <li>High energy costs</li>
                            <li>Out-of-synch production schedules</li>
                            <li>High maintenance cost</li>
                            <li>Sub-standard raw material</li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                            <h4 className="" >AIMLow® Solution </h4>
                            <div className="line-separator-fullwidth"></div>
                          <div className="col-md-6">
                            <i className="icofont-check-circled"></i>
                            <h4 className="title font-weight" >AIMLow® Process Optimization </h4>
                            <p className="standard-color">Increase Production Yield and process efficiency with AI insights</p>
                          </div>
                          <div className="col-md-6">
                            <i className="icofont-check-circled"></i>
                            <h4 className="title font-weight" >AIMLow® Reliability</h4>
                            <p className="standard-color">Know what equipment to fix before it breaks</p>
                          </div>
                          <div className="col-md-6">
                            <i className="icofont-check-circled"></i>
                            <h4 className="title font-weight" >AIMLow® Production Schedule Optimization</h4>
                            <p className="standard-color">Optimize industrial schedules and forecast accurately with AI</p>
                          </div>
                          <div className="col-md-6">
                            <i className="icofont-check-circled"></i>
                            <h4 className="title font-weight" >AIMLow® Energy Management</h4>
                            <p className="standard-color">Reduce energy costs and improve building operations</p>
                          </div>
                          
                        </div>
                      </div>
                    </div>

                    <div className="collapse accordion-content" id="Manage">
                      <div className="row">
                        <div className="col-md-6">
                        <h4 className="" >Key Issues </h4>
                        <div className="line-separator"></div>
                          <ul className="accordion-list">
                            <li>Supply delay risks</li>
                            <li>Transportation bottlenecks</li>
                            <li>Repeated supplier delays</li>
                            <li>Uncertain demand</li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                            <h4 className="" >AIMLow® Solution</h4>
                            <div className="line-separator-fullwidth"></div>
                          <div className="col-md-6">
                            <i className="icofont-check-circled"></i>
                            <h4 className="title font-weight" >AIMLow® Inventory Optimization </h4>
                            <p className="standard-color">Reduce inventory costs with confidence</p>
                          </div>
                          <div className="col-md-6">
                            <i className="icofont-check-circled"></i>
                            <h4 className="title font-weight" >AIMLow® Supply Network Risk</h4>
                            <p className="standard-color">Predict disruptions in delivery times and quality</p>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="about" data-aos="fade-up">
        <div className="container">
          <div className="row padding-height">
            <div class="col-md-12">
              <h3 className="title-size" >Successful Deployments</h3>
            </div>
          </div>
          <div className="row">
          <div class="col-md-7 order-1 order-md-2">
            <img src={sampleImage} class="img-fluid" alt="" />
          </div>
          <div class="col-md-5 pt-5 order-2 order-md-1">
            <h3>Optimizing Inventory Levels for a $30 Billion Global Discrete Manufacturer</h3>
            <p class="color-white">
              Enhanced part-level demand forecasting accuracy and dynamic inventory optimization.
            </p>
            <button class="button-28" role="button">Learn More</button>
          </div>
          </div>
        </div>
      </section>

      <section class="team section-bgsecondary">
      <div class="container">
        <div className="row">
          <h4 className="col-md-6 col-lg-6 title-size">Related Resources</h4>
        </div>
        <div class="row">

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div class="member boxing-style">
              <div class="member-img">
                <img src={sampleImage} class="img-fluid" alt="" />
              </div>
              <div class="member-info">
                <h4>10 Core Principles</h4>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div class="member boxing-style">
              <div class="member-img">
                <img src={sampleImage} class="img-fluid" alt="" />
              </div>
              <div class="member-info">
                <h4>The Live Product Update</h4>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div class="member boxing-style">
              <div class="member-img">
                <img src={sampleImage} class="img-fluid" alt="" />
              </div>
              <div class="member-info">
                <h4>The Principles of Enterprise AI Livestream</h4>
              </div>
            </div>
          </div>
        </div>

      </div>
      </section> */}

    </main>
    </Fragment>   
  )
}

export default Manufacturing;