import React from "react";
import Navigation from "./components/home-page/navigation";
import  AimlowPage  from "./components/products/aimlow-page/AimlowPage";
import Footer from './components/footer-section/footer';
import "./App.css";

const AIMLow = () => {
  return (
    <div>
      <Navigation />
      <AimlowPage />
      <Footer />
    </div>
  );
};

export default AIMLow;
