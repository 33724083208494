import React from "react";
import Navigation from './navigation';
import Home from './home';
import Footer from "../footer-section/footer";

const HomePage = () => {
  return (
    <div>
      <Navigation />
      <Home />
      <Footer />
    </div>
  );
};

export default HomePage;
