import React, { Fragment } from 'react';
import sampleImage from './../../img/sample.jpg';
import video from './../../img/header.mp4';
import "./styles.css";
import FreeDemoForm from './FreeDemoForm';

const NERoML = () => {
  return (
    <Fragment>
      <div className="product-sidebar">
        <ul className="sidebar-list">
          <li className="list-item"><a href="./HyDDEP" className='sidebar-link btn-active'>Overview</a></li>
          <li className="list-item"><a href="./HyDDEP_products" className='sidebar-link'>Product</a></li>
          <li className="list-item"><a href="./NERoML_freedemo" className='sidebar-link'>Free Demo</a></li>
        </ul>
      </div>

    <main id="main">
      <section className="breadcrumbs">
        <div className="container">
        </div>
      </section>

    <section className="contact" >
        <div className="container container-margin">

          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <h4 className="counters-title">NERoML</h4>
                <p className="color-white">Take a look at how our Products can help uncover insights across structured and unstructured data.</p>
                <p className="color-white">This demo is customized around your needs. Get answers to your questions, and find out why Dark Pools is the right solution for you.</p>
              </div>
            </div>
            <div  className="col-lg-6">
              <FreeDemoForm />
            </div>
          </div>

        </div>
      </section>

    </main>
    </Fragment>
  )
}

export default NERoML