import React, { Fragment } from "react";
import Sales from "./../img/Sales.png";
import Alliences from "./../img/Alliance.png";
import Investor from "./../img/Investor-relation.png";
import Other from "./../img/Other-Contacts.png";
import { IconContext } from "react-icons";
import { BsArrowRight } from "react-icons/bs";
import "../App.css";

const ContactUs = () => {
  return (
    <Fragment>
      <main id="main">
        <section className="breadcrumbs">
          <div className="container"></div>
        </section>

        <section
          className="contact section-bgprimary"
          data-aos="fade-up"
          data-aos-easing="ease-in-out"
          data-aos-duration="500"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h3 className="title-size">Contact Us</h3>
                <h4 className="site-text padding-height-bottom">
                  How can we help you?
                </h4>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="contact-box">
                  <a href="./ContactForm_Sales">
                    <div className="contact-wrapper">
                      <div className="text-grouping">
                        <h3 className="title">Sales</h3>
                        <p>Learn how our industry-leading Enterprise software products can help your organization.</p>
                      </div>
                      <img src={Sales} alt={Sales} />
                    </div>
                    <div className="content-wrapper">
                      <p className="contact-bottom-text">Contact Sales </p>
                      <IconContext.Provider value={{ color: "white", className: "global-class-name" }}>
                        <div>
                          <BsArrowRight />
                        </div>
                      </IconContext.Provider>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact-box">
                  <a href="./ContactForm_Alliances">
                    <div className="contact-wrapper">
                      <div className="text-grouping">
                        <h3 className="title">Alliances</h3>
                        <p>Interested in becoming a Dark Pools partner? Contact our Alliances team.</p>
                      </div>
                      <img src={Alliences} alt={Alliences} />
                    </div>
                    <div className="content-wrapper">
                      <p className="contact-bottom-text">Contact Alliances </p>
                      <IconContext.Provider value={{ color: "white", className: "global-class-name" }}>
                        <div>
                          <BsArrowRight />
                        </div>
                      </IconContext.Provider>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact-box">
                  <a href="./ContactForm_Investors">
                    <div className="contact-wrapper">
                      <div className="text-grouping">
                        <h3 className="title">Investors Relation</h3>
                        <p>Contact us to learn more about investing in Dark Pools.</p>
                      </div>
                      <img src={Investor} alt={Investor} />
                    </div>
                    <div className="content-wrapper">
                      <p className="contact-bottom-text">Contact Investors </p>
                      <IconContext.Provider value={{ color: "white", className: "global-class-name" }}>
                        <div>
                          <BsArrowRight />
                        </div>
                      </IconContext.Provider>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact-box">
                  <a href="./ContactForm_Others">
                    <div className="contact-wrapper">
                      <div className="text-grouping">
                        <h3 className="title">Other Enquiries</h3>
                        <p>For all other questions, please contact us here.</p>
                      </div>
                      <img src={Other} alt={Other} />
                    </div>
                    <div className="content-wrapper">
                      <p className="contact-bottom-text">Contact Other Enquiries</p>
                      <IconContext.Provider value={{ color: "white", className: "global-class-name" }}>
                        <div>
                          <BsArrowRight />
                        </div>
                      </IconContext.Provider>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="contact section-bgsecondary"
          data-aos="fade-up"
          data-aos-easing="ease-in-out"
          data-aos-duration="700"
        >
          <div className="container">
            <div className="row">
              <div
                className="col-md-6 col-lg-6 d-flex align-items-stretch"
                data-aos="fade-up"
              >
                <h4 className="title-size">Dark Pools Office</h4>
              </div>

              <div
                className="col-md-6 col-lg-6 d-flex align-items-stretch"
              >
                <div className="col-lg-6">
                  <h4 className="">Address Details:</h4>
                  <p className="description"> 6 North Road, <br /> 
                  Dunkeld Office Park, <br /> 
                  Johannesburg, <br /> 
                  2196
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default ContactUs;
