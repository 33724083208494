import React, { Fragment } from 'react';
import NERoML_Header from '../../../img/NERoML_Header.mp4';
import NERoML_image from '../../assets/images/NERoML.png';
import "../styles.css";

const NERoMLPage = () => {
  return (
    <Fragment>
      <div className="product-sidebar">
        <ul className="sidebar-list">
          <li className="list-item"><a href="./NERoML" className='sidebar-link btn-active'>Overview</a></li>
          <li className="list-item"><a href="./NERoML_Products" className='sidebar-link'>Product </a></li>
          <li className="list-item"><a href="./NERoML_freedemo" className='sidebar-link btn-active'>Free Demo</a></li>
        </ul>
      </div>
    
    <section id="hero" className="d-flex justify-content-center align-items-center">
      <video autoPlay muted loop>
        <source src={NERoML_Header} type="video/mp4" />
      </video>
      <div id="heroCarousel" className="container carousel carousel-fade" data-ride="carousel">

        <div className="carousel-item active">
          <div className="carousel-container">
            <img src={NERoML_image} alt={NERoML_image} className="main-logo"/>
          </div>
        </div>

      </div>
    </section>

    <main id="main">

    <section className="about" data-aos="fade-up">
        <div className="container container-margin">
          <div className="row">
            <div className="col-lg-12 pt-4 pt-lg-0">
              <p className="text-justify title-p standard-color">
                NERoML® enables advanced network and temporal analytics through a rich visual and interactive workflow-enabled interface which allows users to visualize, understand and leverage the hidden relationships between data entities.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class="features">
        <div class="container container-margin">
          <div class="row" data-aos="fade-up">
            <div class="col-md-12">
              {/* <img src={Graph_time_based} class="img-fluid" alt={Graph_time_based} /> */}
              <h1>Add Image here</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="about" data-aos="fade-up">
        <div className="container container-margin">
          <div className="row">
            <div className="col-lg-12 pt-4 pt-lg-0">
              <p className="text-justify title-p standard-color">
                NERoML® also adds data geospatial science to geocode locations of interest on our visualisation dashboard in a consolidated. Large volumes of data with billions of edges and vertices can be visualised on a single view with drill-in capabilities on nodes of interest.
              </p>
            </div>
          </div>
        </div>
      </section>

    </main>
    </Fragment> 
  )
}

export default NERoMLPage;