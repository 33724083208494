import React, { useState } from "react";

const FreeDemoForm = () => {
  const [values, setValues] = useState({
    fullName: "",
    email: "",
    subject: "",
    textarea: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [valid, setValid] = useState(false);

  const handleFullNameInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      fullName: event.target.value,
    }));
  };

  const handleEmailInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      email: event.target.value,
    }));
  };

  const handleSubjectInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      subject: event.target.value,
    }));
  };

  const handleTextAreaInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      textarea: event.target.value,
    }));
};

const handleSubmit = (e) => {
    e.preventDefault();
    if (values.fullName && values.email && values.textarea) {
        setValid(true);
    }
    setSubmitted(true);
    console.log(values);
  };

  return (
    <div className="form-container">
        <div className="form-header">
            <h2 className="form-text-top">Request a Free Demo</h2>
            <p className="form-text">Please complete the form below, to request a free demo, and we'll be in touch.</p>
        </div>
      <form class="free-demo-form" onSubmit={handleSubmit}>
        <div class="inline-form">
          <div class="row-form">
            <input
              id="fullname"
              class="form-field"
              type="text"
              placeholder="Full name"
              name="fullname"
              value={values.fullName}
              onChange={handleFullNameInputChange}
            />
            {submitted && !values.fullName && (
              <span className="form-errors" id="full-name-error">
                Please enter a full name
              </span>
            )}
          </div>
          <div class="row-form">
            <input
            id="email"
            class="form-field"
            type="text"
            placeholder="Email"
            name="email"
            value={values.email}
            onChange={handleEmailInputChange}
            />
            {submitted && !values.email && (
            <span className="form-errors" id="email-error">
                Please enter an email address
            </span>
            )}
          </div>
        </div>

        <input
          id="subject"
          class="form-field"
          type="text"
          placeholder="Subject"
          name="subject"
          value={values.subject}
          onChange={handleSubjectInputChange}
        />

        <textarea
          id="teaxtarea"
          class="form-field"
          type="textarea"
          placeholder="Write your message here..."
          name="textarea"
          value={values.textarea}
          onChange={handleTextAreaInputChange}
          rows="5"
          required
        />
        <button class="form-field" type="submit">
          Get Free Demo
        </button>
        {submitted && (
          <div class="success-message">
            Your message has been sent. Thank you!
          </div>
        )}
      </form>
    </div>
  );
};

export default FreeDemoForm;
