import React from "react";
import Navigation from "./components/home-page/navigation";
import  NERoMLProductPage  from "./components/products/neroml-page/NERoMLProductPage";
import Footer from './components/footer-section/footer';
import "./App.css";

const NERoML_Products = () => {
  return (
    <div>
      <Navigation />
      <NERoMLProductPage />
      <Footer />
    </div>
  );
};

export default NERoML_Products;
