import React from "react";
import Navigation from "../components/home-page/navigation";
import FinancialService from "../components/industries/finance/financial_services_page";
import Footer from '../components/footer-section/footer';

const FinancialServices = () => {
  return (
    <div>
      <Navigation />
      <FinancialService />
      <Footer />
    </div>
  );
};

export default FinancialServices;
