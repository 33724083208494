import React, { Fragment } from 'react';
import video from "../../assets/videos/corporate-business-finance-economy-chart-backgroun.mp4";
// import AIMLow_Header from '../../../img/Resources_Header.mp4';
import AIMLow_image from '../../assets/images/AIMLow.png';
import "../styles.css";
// #123e25 - dark green
// #276955 - dark green
const AimlowPage = () => {
  return (
    <Fragment>
      <div className="product-sidebar">
        <ul className="sidebar-list">
          <li className="list-item">
            <a href="./NERoML" className="sidebar-link btn-active">
              Overview
            </a>
          </li>
          <li className="list-item">
            <a href="./AIMLow_Products" className="sidebar-link">
              Product{" "}
            </a>
          </li>
          <li className="list-item">
            <a href="./NERoML_freedemo" className="sidebar-link btn-active">
              Free Demo
            </a>
          </li>
        </ul>
      </div>

      <section
        id="hero"
        className="d-flex justify-content-center align-items-center"
      >
        <video autoPlay muted loop>
          <source src={video} type="video/mp4" />
        </video>
        <div
          id="heroCarousel"
          className="carousel carousel-fade container"
          data-ride="carousel"
        >
          <div className="carousel-item active">
            <div className="carousel-container">
              <img src={AIMLow_image} alt={AIMLow_image} className="main-logo" />
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section className="about" data-aos="fade-up">
          <div className="container-margin container">
            <div className="row">
              <div className="col-lg-12 pt-lg-0 pt-4">
                <p className="title-p standard-color text-justify">
                  AIMLow makes use of intelligent automation technologies such
                  as supervised and unsupervised ML models with Analytic
                  capabilities which can help better identify emerging patterns
                  and thus provide a better view on a dynamic intuitive and
                  easy-to-use Dashboard.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section class="features">
          <div class="container-margin container">
            <div class="row" data-aos="fade-up">
              <div class="col-md-12">
                {/* <img src={Graph_time_based} class="img-fluid" alt={Graph_time_based} /> */}
                <h1>Add Image here</h1>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default AimlowPage;