import React from "react";
import Navigation from "../components/home-page/navigation";
import RetailServicePage from "../components/industries/retail/retail-services_page";
import Footer from '../components/footer-section/footer';

const Retail = () => {
  return (
    <div>
      <Navigation />
      <RetailServicePage />
      <Footer />
    </div>
  );
};

export default Retail;
