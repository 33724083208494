import React from "react";
import Navigation from "./components/home-page/navigation";
import HyddepPageProduct  from "./components/products/hyddep-page/HyddepPageProduct";
import Footer from './components/footer-section/footer';
import "./App.css";

const HyDDEP_Products = () => {
  return (
    <div>
      <Navigation />
      <HyddepPageProduct />
      <Footer />
    </div>
  );
};

export default HyDDEP_Products;
