import React, { Fragment } from 'react';
import sampleImage from './../../img/sample.jpg';
import Indutries_Header from './../../img/Indutries_Header.mp4';
import "./styles.css";

const Transportation = (props) => {
  return (
    <Fragment>
      <div className="product-sidebar">
        <ul className="sidebar-list">
          <li className="list-item"><a href="#" className='sidebar-link btn-active'>Overview</a></li>
          <li className="list-item"><a href="#" className='sidebar-link'>Application</a></li>
          <li className="list-item"><a href="#" className='sidebar-link'>Use Cases</a></li>
          <li className="list-item"><a href="#" className='sidebar-link'>Next Steps</a></li>
        </ul>
      </div>

      <section id="hero" className="d-flex justify-cntent-center align-items-center">
        <video autoPlay muted loop>
          <source src={Indutries_Header} type="video/mp4" />
        </video>
        <div id="heroCarousel" className="container carousel carousel-fade" data-ride="carousel">

          <div className="carousel-item active">
            <div className="">
              <div ></div>
              <h2 className="animated fadeInDown"><span>Dark Pools for</span></h2>
              <p className="animated fadeInUp main-text">Transportations</p>
            </div>
          </div>

        </div>
      </section>

    <main id="main">

    <section className="about" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 pt-4 pt-lg-0">
              <h3>Dark Pools AI Addresses Key Issues Across the Telecommunications Value Chain</h3>
              <p className="p-text standard-color">
                Dark Pools AI helps telecom organizations use data-driven predictive insights to offer the right 
                products to the right customers at the right time.
              </p>
            </div>
          </div>
          <div className="row row-padding">
            <div className="col-lg-12 pt-4 pt-lg-0">
              <img src={sampleImage} class="img-fluid" alt="" />
          </div>
          </div>
        </div>
      </section>

      <section className="about" data-aos="fade-up">
        <div className="container">
          <div className='row'>
            <div className="col-lg-12">
            <h3>Applications</h3>
            </div>
          </div>
          <div className='row row-padding'>
            <div className="col-lg-6">
              <h3>AIMLow® Reliability</h3>
              <p className='color-white'>Aggregate petabyte-scale data from sensors, devices, enterprise systems, and operational systems to generate accurate predictions of asset failure. AIMLow® Reliability provides planners and operators with comprehensive insight into asset risk, enabling them to maintain higher levels of asset availability, deliver high-quality network services, and reduce maintenance costs</p>
              <button class="button-28" role="button">Learn More</button>
            </div>
            <div className="col-lg-6">
            <h3>AIMLow® </h3>
              <p className="color-white">Leverage AI/machine learning to enable the proactive identification of customers at risk of churning, targeted cross-sell and up-sell opportunities for existing customers, and a shift to proactive vs. reactive after-sale services. AIMLow® Sensor Health</p>
              <p className="color-white">Ensure the operational health and optimal deployment of IoT sensor devices with machine learning. AIMLow® Sensor Health uses advanced AI/machine learning to predict sensor failures and identify sensor and network health issues with a high degree of precision</p>
              <button class="button-28" role="button">Learn More</button>
            </div>
          <div className='row row-padding'>
            <div className="col-lg-6">
              <h3>AIMLow® Energy Management</h3>
              <p className="color-white">Reduce energy costs and improve building operations through real-time tracking, analytics, and optimization. AIMLow® Energy Management uses machine learning techniques to enable accurate forecasting, benchmarking, demand response, and anomaly detection to lower costs, improve operations, and meet energy-efficiency goals.</p>
              <button class="button-28" role="button">Learn More</button>
            </div>
            <div className="col-lg-6">
              <h3>AIMLow® Inventory Optimization</h3>
              <p className="color-white">Reduce inventory holding costs and improve cash flow and parts supply chain visibility. AIMLow® Inventory Optimization applies advanced machine learning to analyze variability in demand, supplier delivery times, and quality issues to build real-time recommendations and monitoring, so users can set optimization by confidence level and receive real-time notifications and root-cause analysis.</p>
              <button class="button-28" role="button">Learn More</button>
            </div>
          </div>
          </div>
        </div>
      </section>

      <section className="services section-bgprimary">
        <div className="container">
       
        <div className="row">
          <h4 className="col-md-6 col-lg-6 title-size">Use Cases ****</h4>
        </div>

        <div className='row row-padding'>
          <div className="col-lg-6">
            <i className="icofont-check-circled"></i>
            <h4 className="font-bold">Vehicle Path Optimization</h4>
            <p className="color-white">Use AI-enabled delivery, vehicle routing, and scheduling to reduce operational costs and improve customer service.</p>
          </div>
          <div className="col-lg-6">
            <i className="icofont-check-circled"></i>
            <h4 className="font-bold">Predictive Workforce Planning</h4>
            <p className="color-white">Use AI-enabled demand forecasting for improved staff, vehicle, and capacity planning.</p>
          </div>
        </div>
      
        <div className='row row-padding'>
          <div className="col-lg-12">
            <h4 className="font-bold">Address use cases with the C3 AI Application Platform</h4>
            <p className="color-white">The C3 AI Application Platform is a purpose-built platform for developing and operating AI applications that address industry and company-specific use cases. It offers a cohesive, low-code/no-code development environment with a complete and comprehensive set of tools and services to design, build, deploy, and operate advanced, enterprise-scale AI applications.</p>
            <button class="button-28" role="button">Explore the AI Application Platform</button>
          </div>
        </div>
      </div>
      </section>
    </main>
    </Fragment>
  )
}

export default Transportation