import { useState, useEffect } from "react";
import Navigation from "./components/home-page/navigation";
import  Manufacturing_page  from "./components/industries/manufacturing_page";
import Footer from './components/footer-section/footer';
import "./App.css";

const Manufacturing = () => {
  return (
    <div>
      <Navigation />
      <Manufacturing_page />
      <Footer />
    </div>
  );
};

export default Manufacturing;
