import React from "react";
import Navigation from "../components/home-page/navigation";
import FinancialProducts from "../components/industries/finance/financial_service_product_page";
import Footer from '../components/footer-section/footer';

const FinancialProduct = () => {
  return (
    <div>
      <Navigation />
      <FinancialProducts />
      <Footer />
    </div>
  );
};

export default FinancialProduct;
