import { useState, useEffect } from "react";
import Navigation from "./components/home-page/navigation";
import  ContactForm_Sales_page  from "./components/contactform_sales_page";
import Footer from './components/footer-section/footer';
import "./App.css";

const ContactForm_Sales = () => {
  return (
    <div>
      <Navigation />
      <ContactForm_Sales_page />
      <Footer />
    </div>
  );
};

export default ContactForm_Sales;
