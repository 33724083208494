import React, { Fragment } from 'react';
import sampleImage from './../../img/sample.jpg';
import Indutries_Header from './../../img/Indutries_Header.mp4';
import "./styles.css";

const UtilitiesProduct = (props) => {
  return (
    <Fragment>
      <div className="product-sidebar">
        <ul className="sidebar-list">
          <li className="list-item"><a href="./Utilities" className='sidebar-link btn-active'>Overview</a></li>
          <li className="list-item"><a href="./UtilitiesProduct" className='sidebar-link'>Products</a></li>
          <li className="list-item"><a href="./Customers" className='sidebar-link'>Customer</a></li>
          <li className="list-item"><a href="./Resources" className='sidebar-link'>Resources</a></li>
        </ul>
      </div>  

    <section id="hero" className="d-flex justify-cntent-center align-items-center">
      <video autoPlay muted loop>
        <source src={Indutries_Header} type="video/mp4" />
      </video>
      <div id="heroCarousel" className="container carousel carousel-fade" data-ride="carousel">

        <div className="carousel-item active">
          <div className="">
            <div ></div>
            <h2 className="animated fadeInDown"><span>Dark Pools for</span></h2>
            <p className="animated fadeInUp main-text">Utilities Product</p>
          </div>
        </div>

      </div>
    </section>

    <main id="main">

      <section className="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 title-size">
              <h3>AIMLow® Reliability</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h3>Industry Problem</h3><br/><br />
              <p className="color-white">
                Utility plant operators lack the ability to effectively understand asset risk and prioritize 
                maintenance activity and expenditures. This can lead to significant unplanned downtime, cause 
                catastrophic failure leading to community safety risks, and cost companies millions in expense 
                and lost production capacity.
              </p>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h3>AIMLow® Solution </h3><br/><br />
              <ul className="accordion-list">
                <li>Reduce or eliminate unplanned failures through AI-based early identification and prioritization of high-risk conditions</li>
                <li>Maximize asset life and reduce replacement costs by avoiding catastrophic failure</li>
                <li>Improve maintenance planning and reduce maintenance cost by scheduling maintenance based on unit or facility condition</li>
                <li>Enable asset-level diagnostics and projections</li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 pt-4 pt-lg-0">
              <div className="link-panel">
                <a href="#" className="" >Learn More</a> | <a href="#" className="">Free Demo</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 title-size">
              <h3>AIMLow® Energy Management</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h3>Industry Problem</h3><br/><br />
              <p className="color-white">
                Managing the full life cycle of energy and environmental sustainable management - from data integration 
                to analysis and opportunity identification, through mitigation project execution, and, finally, to 
                reporting and continuous improvement is critical for Utilities. Energy waste, unanticipated demand 
                combined with climate change responsibility can lead to high costs and be a drag on profitability.
              </p>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h3>AIMLow® Solution </h3><br/><br />
              <ul className="accordion-list">
                <li>Reduce energy expenditures using predictive analytics to identify high-value savings opportunities</li>
                <li>Forecast consumption and predict peak demand events with tailored machine learning algorithms that provide high degrees of accuracy</li>
                <li>Monitor asset health and equipment efficiency in real time to improve system reliability and manage costs</li>
                <li>Achieve sustainability goals with tailored carbon-reduction insights, AI-powered measurement & verification, and streamlined financial and carbon emissions reporting</li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 pt-4 pt-lg-0">
              <div className="link-panel">
                <a href="#" className="" >Learn More</a> | <a href="#" className="">Free Demo</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 title-size">
              <h3>AIMLow® AMI Operations</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h3>Industry Problem</h3><br/><br />
              <p className="color-white">
                As utilities and energy suppliers replace legacy meters with advanced meters, deploy new smart meters, 
                or seek to optimize existing meter networks, they need efficient and end-to-end processes. Electricity 
                providers require the capability to track progress and issues during smart meter installation, provide 
                visibility into real-time smart meter operation and performance to improve reliability, enable targeted 
                issue resolution, and increase billing accuracy.
              </p>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h3>AIMLow® Solution </h3><br/><br />
              <ul className="accordion-list">
                <li>Identify AMI and AMR deployment issues, diagnose the source of meter malfunction, optimize field service, and forecast future deployment progress</li>
                <li>Ensure AMI asset installation information is correct and consistent across utility data systems (AMI head-end, customer information, meter data management, workforce management, billing, asset management, and GIS).</li>
                <li>Identify the root cause of failures; provide recommendations for solutions resolving the root cause and any related issues</li>
                <li>Receive alerts of real-time critical events from AMI network assets, including faults, communication bottlenecks, voltage discrepancies, and security breaches</li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 pt-4 pt-lg-0">
              <div className="link-panel">
                <a href="#" className="" >Learn More</a> | <a href="#" className="">Free Demo</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 title-size">
              <h3>AIMLow® CRM </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h3>Industry Problem</h3><br/><br />
              <p className="color-white">
                Traditional general-purpose CRM applications are no longer sufficient to address the dynamics of today's 
                fast-moving global markets and the mandates of digital transformation. A new AI-first CRM is required to 
                increase the success of new product and service offerings, increase distributed energy usage, improve 
                customer service and satisfaction, and increase efficiency of power grid operations.
              </p>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h3>AIMLow® Solution </h3><br/><br />
              <ul className="accordion-list">
                <li>Accurately forecast revenue with AI in order to support regulatory and capital planning that supports 
                    meeting customer needs, optimizing rates, and operating at the lowest cost to serve</li>
                <li>Generate accurate demand/load and price forecasts, while supporting end-to-end operations, balancing generation, 
                    validating production plans, and planning expansion projects</li>
                <li>Customer Churn Management</li>
                <li>Next-best offer</li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 pt-4 pt-lg-0">
              <div className="link-panel">
                <a href="#" className="" >Learn More</a> | <a href="#" className="">Free Demo</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 title-size">
              <h3>AIMLow® Fraud Detection  </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h3>Industry Problem</h3><br/><br />
              <p className="color-white">
                Businesses are consistently challenged with instances of non-technical revenue loss or operational 
                loss due to physical breaches, fraudulent payments, or stolen IP. In the utility industry alone, 20 - 60 
                billion in energy is lost to power theft globally each year. Without an aggregate view of all available 
                customer and internal data coupled with device telemetry, firms struggle to effectively and accurately 
                identify cases of theft or breach, rectify issues or unsafe conditions, and safeguard revenue.
              </p>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h3>AIMLow® Solution </h3><br/><br />
              <ul className="accordion-list">
                <li>Access a summary of the work queue, operational performance, prioritized cases, and geospatial view of occurrences of theft or other loss</li>
                <li>Detect physical and informational breaches; correlate unusual customer patterns with device diagnostics and operations</li>
                <li>Identify and prioritize high-value and high-likelihood cases with increasing precision based on historical confirmed instances of theft or other loss</li>
                <li>Monitor and analyse identified cases, investigations in the field, and completed investigations, and review results</li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 pt-4 pt-lg-0">
              <div className="link-panel">
                <a href="#" className="" >Learn More</a> | <a href="#" className="">Free Demo</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 title-size">
              <h3>AIMLow® Energy Management</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h3>Industry Problem</h3><br/><br />
              <p className="color-white">
                Manufacturing operations can be quite energy intensive. Manufacturing facilities alone are responsible 
                for spending over 200 billion every year to power facilities and waste nearly 30 percent of that energy. 
                There can be various causes of energy waste in manufacturing including leaky air compressors, inefficient 
                equipment, and unnecessary heating/cooling in buildings. Energy waste combined with unanticipated demand 
                can lead to conflated energy costs and be a drag on profitability
              </p>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h3>AIMLow® Solution </h3><br/><br />
              <ul className="accordion-list">
                <li>Reduce energy expenditures using predictive analytics to identify high-value savings opportunities</li>
                <li>Forecast consumption and predict peak demand events with tailored machine learning algorithms that provide high degrees of accuracy</li>
                <li>Monitor asset health and equipment efficiency in real time to improve system reliability and manage costs</li>
                <li>Optimize cost- and carbon-saving investments with machine learning insights and 'hat if' scenario analysis</li>
                <li>Achieve sustainability goals with tailored carbon-reduction insights, AI-powered measurement & verification, and streamlined financial and carbon emissions reporting</li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 pt-4 pt-lg-0">
              <div className="link-panel">
                <a href="#" className="" >Learn More</a> | <a href="#" className="">Free Demo</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    </Fragment>   
  )
}

export default UtilitiesProduct;