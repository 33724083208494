import React, { useState, Fragment } from "react";
import video from "../../assets/videos/ai-working.mp4";
import image2 from "../../assets/images/industries/image2.png";
import "./retail-styles.css";
import "../styles.css";
import { keyAreaData } from "./key-areas";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { IconContext } from "react-icons";

const RetailServicePage = () => {
  const [keyPoints, setKeyPoints] = useState(keyAreaData[0].keyIssues);
  return (
    <Fragment>
      <div className="product-sidebar">
        <ul className="sidebar-list">
          <li className="list-item">
            <a href="./Retail" className="sidebar-link btn-active">
              Overview
            </a>
          </li>
          <li className="list-item">
            <a href="./RetailProducts" className="sidebar-link">
              Products
            </a>
          </li>
          <li className="list-item">
            <a href="./Customer" className="sidebar-link">
              Free Demo
            </a>
          </li>
        </ul>
      </div>

      <section
        id="hero"
        className="d-flex justify-cntent-center align-items-center"
      >
        <video autoPlay muted loop>
          <source src={video} type="video/mp4" />
        </video>
        <div
          id="heroCarousel"
          className="carousel carousel-fade container"
          data-ride="carousel"
        >
          <div className="carousel-item active">
            <div className="">
              <div></div>
              <h2 className="animated fadeInDown main-heading">
                <span>Dark Pools</span>
              </h2>
              <p className="animated fadeInUp main-text">Retail Services</p>
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section className="about-section">
          <div className="block">
            <span id="overview-heading">
              Dark Pools Addresses Key Issues Across the Retail Value Chain
            </span>
            <p className="overview-text">
              Helping retail organizations use shopper, transactional, and
              category trend data for predictive insights that improve
              transactional volume and customer profitability, minimize supply
              chain costs, and optimize store operations.
            </p>
            <div className="inside-content-wrapper">
              <p className="overview-text">
                Dark Pools Reduces inventory holding costs, improve cash flow and
                supply chain visibility, and increase the productivity of
                inventory analysts. Applying advanced machine learning to analyze
                variability in demand, supplier delivery times, and quality issues
                to build real-time recommendations and monitoring, so users can
                set optimization by confidence level and receive real-time
                notifications and root-cause analysis.
              </p>
              <div className="image-wrapper">
                <img src={image2} alt={image2} className="main-images"/>
              </div>
            </div>
          </div>
        </section>

        <section className="middle-section">
          <div className="block">
            <div className="product-panel-block">
              {keyAreaData.map((data, i) => (
                <span key={i} onClick={() => setKeyPoints(data.keyIssues)}>
                  {data.title}
                </span>
              ))}
            </div>
            <div className="retail-panel-content">
              <div className="p-tag">
                <p>Key Issues</p>
              </div>
              <div className="key-issues-container">
                {keyPoints.map((keyIssue, i) => (
                  <div className="key-issues" key={i}>
                      <IconContext.Provider
                        value={{
                          color: "green",
                          className: "global-class-name",
                        }}
                      >
                          <IoMdCheckmarkCircleOutline />
                      </IconContext.Provider>
                    <span id="spacing">{keyIssue}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default RetailServicePage;
