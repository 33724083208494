export const keyAreaData = [
  {
    title: 'Commercial/Retail',
    keyIssues: ['Imprecise forecasts', 'Expensive customer churn', 'Missed up-sell and x-sell opportunities', 'Complex rate and fee structure', 'Fragmented view of customer profitability', ]
  },
  {
    title: 'Products',
    keyIssues: ['Lengthy processes', 'Slow decision-making', 'Infrequent client touch-points', 'Siloed customer information', 'One-size-fits-all client experience', ]
  },
]

export const keyAreaProducts = [
  {
    title: 'Use Cases',
    keyIssues: ['Promotion Optimization', 'Delivery Optimization', 'Demand Forecasting', 'Inventory Optimization']
  },
  {
    title: 'Key Capabilities',
    keyIssues: ['Proactive Insights', 'Improve customer/Product strategies', 'Forecast, Predict and Segment accurately ', 'Inventory Management', 'Anomaly Detection']
  },
]