import React from "react";
import Navigation from "../components/home-page/navigation";
import TelecommunicationPage from "../components/industries/telecommunication/telecom_services_page";
import Footer from '../components/footer-section/footer';

const Telecommunications = () => {
  return (
    <div>
      <Navigation />
      <TelecommunicationPage />
      <Footer />
    </div>
  );
};

export default Telecommunications;
