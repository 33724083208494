import React from "react";
import Navigation from "../components/home-page/navigation";
import GovernmentProductPage from "../components/industries/government/government_product_page";
import Footer from '../components/footer-section/footer';

const GovernmentProducts = () => {
  return (
    <div>
      <Navigation />
        <GovernmentProductPage />
      <Footer />
    </div>
  );
};

export default GovernmentProducts;
