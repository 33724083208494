import React, { Fragment } from 'react';
import sampleImage from './../../img/sample.jpg';
import NERoML_Header from './../../img/NERoML_Header.mp4';
import "./styles.css";


const NERoML = () => {
  return (
    <Fragment>
        <div className="product-sidebar">
          <ul className="sidebar-list">
            <li className="list-item"><a href="./NERoML" className='sidebar-link btn-active'>OverView</a></li>
            <li className="list-item"><a href="./WhyNERoML" className='sidebar-link btn-active'>Why NERoML®</a></li>
            <li className="list-item"><a href="./DataIntegration" className='sidebar-link'>Data Integration </a></li>
          </ul>
        </div>

      <section id="hero" className="d-flex justify-content-center align-items-center">
      <video autoPlay muted loop>
        <source src={NERoML_Header} type="video/mp4" />
      </video>
      <div id="heroCarousel" className="container carousel carousel-fade" data-ride="carousel">

        <div className="carousel-item active">
          <div className="carousel-container">
            <h2 className="animated fadeInDown"><span>NERoML</span></h2>
            <p className="animated fadeInUp">Why NERoML?</p>
          </div>
        </div>

      </div>
    </section>

    <main id="main">
      <section className="breadcrumbs">
        <div className="container">
        </div>
      </section>
    <section class="features">
      <div class="container container-margin">
        <div className="row">
          <div class="col-md-12">
            <h4 className="title-size">Why is NERoML® distinctive?</h4>
            <p class="p-text standard-color">
              NERoML® uses model-based architecture to accelerate delivery and reduce complexities of developing 
              enterprise-scale AI applications. AIMLow® reduces the amount of code developers write and decreases 
              the effort required to develop and deploy AI applications as compared to <strong>traditional structured 
              programming</strong> approaches.
            </p>
            <p class="p-text standard-color">
              Enterprise AI applications usually require underlying elements : Data persistence services, data 
              streaming services, messaging services, analytics services, machine learning services, security services, 
              and numerous more.
            </p>
            <p class="p-text standard-color">
              With <strong>traditional structured programing</strong> approach, developers devote significant time and effort 
              in writing code to define, manage, connect, and control each element. This results in overwhelming 
              complexity and highly fragile applications that may break any time an underlying element is changed or updated
            </p>
            <p class="p-text standard-color">
              The model-based architecture enables the use of <strong>declarative programming</strong>, an efficient approach that 
              allows programmers to specify what they want the application to do, without having to write 
              explicit code on how to do it. Compared to traditional structured programming, 
              the model-based architecture and declarative programming shorten time to value and reduce total 
              cost of ownership. Leveraging this model-based architecture, developers and data scientists can 
              focus on delivering immediate value, without the need to manage the complexities of the underlying elements. 
              These conceptual models can also be used by many applications, further accelerating development of new applications.
            </p>
          </div>
        </div>
        <div class="row" data-aos="fade-up">
          <div class="col-md-6">
            <h3>Enterprise wide collaboration</h3>
            <p class="p-text standard-color">
              The NERoML® model-based architecture serves as an object-centric abstraction layer removing 
              complexities and barriers across developers, data scientists, and business end users.
            </p><br /><br />
            <ul className='accordion-list'>
              <li>Improved adoption and understanding of AI across the enterprise</li>
              <li>Enhanced collaboration across development teams and business end users through
                  object representation of the business problem</li>
            </ul>
          </div>
          <div class="col-md-6">
            <h3>Scalability</h3>
            <ul className='accordion-list'>
              <li>Application development with less code, fewer resources, and reduced maintenance requirements</li>
              <li>Massively expandable architecture across applications and the enterprise​</li>
              <li>Unmatched flexibility and scalability through reusability of object models developed within NERoML® </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    
      {/* <section class="team section-bgsecondary">
      <div class="container">
        <div className="row">
          <h4 className="col-md-6 col-lg-6 title-size">Related Resources</h4>
        </div>
        <div class="row">

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div class="member boxing-style">
              <div class="member-img">
                <img src={sampleImage} class="img-fluid" alt="" />
              </div>
              <div class="member-info">
                <h4>10 Core Principles</h4>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div class="member boxing-style">
              <div class="member-img">
                <img src={sampleImage} class="img-fluid" alt="" />
              </div>
              <div class="member-info">
                <h4>The Live Product Update</h4>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div class="member boxing-style">
              <div class="member-img">
                <img src={sampleImage} class="img-fluid" alt="" />
              </div>
              <div class="member-info">
                <h4>The Principles of Enterprise AI Livestream</h4>
              </div>
            </div>
          </div>
        </div>

      </div>
      </section> */}

    </main>
    </Fragment>
  )
}

export default NERoML