import React, { useState, Fragment } from "react";
import video from "../../assets/videos/global-finance.mp4";
import img1 from "../../../img/Data-Object-model.jpg";
import img2 from "../../../img/Link-analysis.png";
import img3 from "../../../img/Data-Lake.jpg";
import "./finance-styles.css";
import "../styles.css";
import { keyAreaProducts } from "./key-areas";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { IconContext } from "react-icons";

const FinancialProducts = () => {
  const [keyPoints, setKeyPoints] = useState(keyAreaProducts[0].keyIssues);
  return (
    <Fragment>
      <div className="product-sidebar">
        <ul className="sidebar-list">
          <li className="list-item">
            <a href="./FinancialServices" className="sidebar-link btn-active">
              Overview
            </a>
          </li>
          <li className="list-item">
            <a href="./FinancialProducts" className="sidebar-link">
              Products
            </a>
          </li>
          <li className="list-item">
            <a href="./Customers" className="sidebar-link">
              Free Demo
            </a>
          </li>
        </ul>
      </div>

      <section
        id="hero"
        className="d-flex justify-cntent-center align-items-center"
      >
        <video autoPlay muted loop>
          <source src={video} type="video/mp4" />
        </video>
        <div
          id="heroCarousel"
          className="carousel carousel-fade container"
          data-ride="carousel"
        >
          <div className="carousel-item active">
            <div className="">
              <div></div>
              <h2 className="animated fadeInDown main-heading">
                <span>Dark Pools</span>
              </h2>
              <p className="animated fadeInUp main-text">Financial Products</p>
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section className="about-section">
          <div className="block">
            <div className="content-section">
              <span id="overview-heading">Industry Problem</span>
              <p className="overview-text">
                Enforcement actions and penalties for non-compliance with AML
                regulations are on the rise. Banks are saddled with increasingly
                higher costs for transaction monitoring or facing the risks of
                hefty fines. At the same time bad actors are employing an
                increasingly diverse set of strategies to stay ahead of
                regulators and compliance departments. Current rules-based AML
                software creates a slew of cases that require extensive and
                manual investigation by compliance officers.
              </p>
            </div>
            <div className="content-section">
              <div className="text-box-left">
                <span id="overview-heading">AIMLow</span>
                <p className="overview-text">
                  Leverages the power of Data, Analytics, Machine Learning , and
                  AI technologies to uncover hidden relationships from vast
                  troves of data, generate insights, and inform decision making
                  across all levels. Improve accuracy of money laundering case
                  detection and efficiency of investigators and financial crime
                  compliance managers.
                </p>
              </div>
              <div>
                <img src={img1} alt={"img1"} width="320px" />
              </div>
            </div>
            <div className="content-section">
              <div className="text-box-left">
                <img src={img2} alt={"img1"} width="320px" />
              </div>
              <div>
                <span id="overview-heading">NERoML</span>
                <p className="overview-text">
                  Enhance your financial detection patterns with machine
                  learning, data visualization on a sophisticated Network
                  visualization platform and uncovers difficult-to-detect
                  patterns that far outstrip the power of a relational database.
                </p>
              </div>
            </div>
            <div className="content-section">
              <div className="text-box-left">
                <span id="overview-heading">HyDDEP</span>
                <p className="overview-text">
                  Enabling you to rationalize, integrate, consolidate, and
                  seamlessly share volumes of data across the organization
                  through a synchronized platform. It enables financial
                  institutes to effectively manage and analyse data and extract
                  trusted insights to expand market share.
                </p>
              </div>
              <div>
                <img src={img3} alt={"img1"} width="320px" />
              </div>
            </div>
          </div>
        </section>

        <section className="middle-section">
          <div className="block">
            <div className="product-panel-block">
              {keyAreaProducts.map((data, i) => (
                <span key={i} onClick={() => setKeyPoints(data.keyIssues)}>
                  {data.title}
                </span>
              ))}
            </div>
            <div className="finance-panel-content">
              <div className="p-tag">
                <p>Key Issues</p>
              </div>
              <div className="key-issues-container">
                {keyPoints.map((keyIssue, i) => (
                  <div className="key-issues" key={i}>
                    <IconContext.Provider
                      value={{
                        color: "green",
                        className: "global-class-name",
                      }}
                    >
                      <IoMdCheckmarkCircleOutline />
                    </IconContext.Provider>
                    <span id="spacing">{keyIssue}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default FinancialProducts;
