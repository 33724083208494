import { useState, useEffect } from "react";
import Navigation from "./components/home-page/navigation";
import  ContactForm_Investors_page  from "./components/contactform_investors_page";
import Footer from './components/footer-section/footer';
import "./App.css";

const ContactForm_Investors = () => {
  return (
    <div>
      <Navigation />
      <ContactForm_Investors_page />
      <Footer />
    </div>
  );
};

export default ContactForm_Investors;
