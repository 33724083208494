import { useState, useEffect } from "react";
import Navigation from "./components/home-page/navigation";
import  Transportation_page  from "./components/industries/transportation_page";
import Footer from './components/footer-section/footer';
import "./App.css";

const Transportation = () => {
  return (
    <div>
      <Navigation />
      <Transportation_page />
      <Footer />
    </div>
  );
};

export default Transportation;
