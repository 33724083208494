import { useState, useEffect } from "react";
import Navigation from "./components/home-page/navigation";
import  Healthcare_page  from "./components/industries/healthcare_page";
import Footer from './components/footer-section/footer';
import "./App.css";

const Healthcare = () => {
  return (
    <div>
      <Navigation />
      <Healthcare_page />
      <Footer />
    </div>
  );
};

export default Healthcare;
