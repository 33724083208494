import {BrowserRouter, Routes, Route } from "react-router-dom"
import HyDDEP from "./HyDDEP"
import AIMLow from "./AIMLow"
import AIMLow_Products from "./AIMLow_Products"
import NERoML from "./NERoML"
import HyDDEP_Products from "./HyDDEP_Products"
import NERoML_Freedemo from "./NERoML_Freedemo"
import WhyNERoML from "./WhyNERoML"
import NERoML_Products from "./NERoML_Products"
import Manufacturing from "./Manufacturing"
import ManufacturingProduct from "./ManufacturingProduct"
import OilAndGas from "./OilAndGas"
import Utilities from "./Utilities"
import UtilitiesProduct from "./UtilitiesProduct"
import FinancialServices from "./route_components/FinancialServices"
import FinancialProduct from "./route_components/FinancialProduct"
import Government from "./route_components/Government"
import GovernmentProduct from "./route_components/GovernmentProducts"
import Healthcare from "./Healthcare"
import Retail from "./route_components/Retail"
import RetailProducts from "./route_components/RetailProducts"
import Telecommunication from "./route_components/Telecommunication"
import TelecommunicationProduct from "./route_components/TelecommunicationProducts"
import Transportation from "./Transportation"
import Customers from "./Customers"
import Resources from "./Resources"
import ContactUs from "./ContactUs"
import ContactForm_Sales from "./ContactForm_Sales"
import ContactForm_Alliances from "./ContactForm_Alliances"
import ContactForm_Investors from "./ContactForm_Investors"
import ContactForm_Others from "./ContactForm_Others"
import HomePage from "./components/home-page/home-page"

const NavPath = () => {

  return(
    <BrowserRouter>

      <Routes>
        <Route element={<HomePage />} path="/"></Route>
      </Routes>

      {/* <Routes>
        <Route element={<HomeOne />} path="homeone"></Route>
      </Routes> */}

      <Routes>
        <Route element={<HyDDEP />} path="HyDDEP"></Route>
      </Routes>

      <Routes>
        <Route element={<AIMLow />} path="AIMLow"></Route>
      </Routes>

      <Routes>
        <Route element={<AIMLow_Products />} path="AIMLow_Products"></Route>
      </Routes>

      <Routes>
        <Route element={<NERoML />} path="NERoML"></Route>
      </Routes>

      <Routes>
        <Route element={<HyDDEP_Products />} path="HyDDEP_Products"></Route>
      </Routes>

      <Routes>
        <Route element={<NERoML_Freedemo />} path="NERoML_Freedemo"></Route>
      </Routes>

      <Routes>
        <Route element={<WhyNERoML />} path="WhyNERoML"></Route>
      </Routes>

      <Routes>
        <Route element={<NERoML_Products />} path="NERoML_Products"></Route>
      </Routes>

      <Routes>
        <Route element={<Manufacturing />} path="Manufacturing"></Route>
      </Routes>

      <Routes>
        <Route element={<ManufacturingProduct />} path="ManufacturingProduct"></Route>
      </Routes>

      <Routes>
        <Route element={<OilAndGas />} path="OilAndGas"></Route>
      </Routes>

      <Routes>
        <Route element={<Utilities />} path="Utilities"></Route>
      </Routes>

      <Routes>
        <Route element={<UtilitiesProduct />} path="UtilitiesProduct"></Route>
      </Routes>

      <Routes>
        <Route element={<FinancialServices />} path="FinancialServices"></Route>
      </Routes>

      <Routes>
        <Route element={<FinancialProduct />} path="FinancialProducts"></Route>
      </Routes>

      <Routes>
        <Route element={<Government />} path="Government"></Route>
      </Routes>
      <Routes>
        <Route element={<GovernmentProduct />} path="GovernmentProducts"></Route>
      </Routes>

      <Routes>
        <Route element={<Healthcare />} path="Healthcare"></Route>
      </Routes>
      
      <Routes>
        <Route element={<Retail />} path="Retail"></Route>
      </Routes>
      
      <Routes>
        <Route element={<RetailProducts />} path="RetailProducts"></Route>
      </Routes>

      <Routes>
        <Route element={<Telecommunication />} path="Telecommunication"></Route>
      </Routes>

      <Routes>
        <Route element={<TelecommunicationProduct />} path="TelecommunicationProducts"></Route>
      </Routes>

      <Routes>
        <Route element={<Transportation />} path="Transportation"></Route>
      </Routes>

      <Routes>
        <Route element={<Customers />} path="Customers"></Route>
      </Routes>

      <Routes>
        <Route element={<Resources />} path="Resources"></Route>
      </Routes>

      <Routes>
        <Route element={<ContactUs />} path="contactus"></Route>
      </Routes>

      <Routes>
        <Route element={<ContactForm_Sales />} path="ContactForm_Sales"></Route>
      </Routes>

      <Routes>
        <Route element={<ContactForm_Alliances />} path="ContactForm_Alliances"></Route>
      </Routes>

      <Routes>
        <Route element={<ContactForm_Investors />} path="ContactForm_Investors"></Route>
      </Routes>

      <Routes>
        <Route element={<ContactForm_Others />} path="ContactForm_Others"></Route>
      </Routes>


    </BrowserRouter>
  )

}

export default NavPath