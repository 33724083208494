import React from "react";
import Finance from "../assets/images/Dark-Pools imgs/Finance.png";
import Government from "../assets/images/Dark-Pools imgs/Utility.png";
import Retail from "../assets/images/Dark-Pools imgs/Retail.png";
import Telecommunication from "../assets/images/Dark-Pools imgs/Telecom.png";
import './style.css';

const Industries = () => {
  return (
    <ul className="unordered">
      <li className="list">
        <a href="./FinancialServices">
          <img
            src={Finance}
            alt={"Landing Banking"}
            width="200px"
            className="float-inDown"
            style={{animationDelay: "1s"}}
          />
          <p className="float-inUp" style={{animationDelay: "1s"}}><strong>Financial Services</strong></p>
        </a>
      </li>
      <hr/>
      <li className="list">
        <a href="./Government">
          <img
            src={Government}
            alt={"Landing Aerospace Defense"}
            width="200px"
            className="float-inDown"
            style={{animationDelay: "2s"}}
          />
          <p className="float-inUp" style={{animationDelay: "2s"}}><strong>Government</strong></p>
        </a>
      </li>
      <hr/>
      <li className="list">
        <a href="./Retail">
          <img
            src={Retail}
            alt={"Landing Retail"}
            width="200px"
            className="float-inDown"
            style={{animationDelay: "3s"}}
          />
          <p className="float-inUp" style={{animationDelay: "3s"}}><strong>Retail</strong></p>
        </a>
      </li>
      <hr/>
      <li className="list">
        <a href="./Telecommunication">
          <img
            src={Telecommunication}
            alt={"Landing Telecommunication"}
            width="200px"
            className="float-inDown"
            style={{animationDelay: "4s"}}
          />
          <p className="float-inUp" style={{animationDelay: "4s"}}><strong>Telecommunication</strong></p>
        </a>
      </li>
    </ul>
  );
};

export default Industries;
