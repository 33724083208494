import React, { useState, Fragment } from "react";
import video from "../../assets/videos/global-finance.mp4";
import "./finance-styles.css";
import "../styles.css";
import { keyAreaData } from "./key-areas";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { IconContext } from "react-icons";
import img1 from "../../assets/images/Dark-Pools imgs/Finance.png";
import Finance from "../../assets/images/industries/Dark-Pools-AI-in-Finance.png";
import image1 from "../../assets/images/industries/image1.png";


const FinancialService = () => {
  const [keyPoints, setKeyPoints] = useState(keyAreaData[0].keyIssues);
  return (
    <Fragment>
      <div className="product-sidebar">
        <ul className="sidebar-list">
          <li className="list-item active">
            <a href="./FinancialServices" className="sidebar-link btn-active">
              Overview
            </a>
          </li>
          <li className="list-item">
            <a href="./FinancialProducts" className="sidebar-link">
              Products
            </a>
          </li>
          <li className="list-item">
            <a href="./Customers" className="sidebar-link">
              Free Demo
            </a>
          </li>
        </ul>
      </div>

      <section
        id="hero"
        className="d-flex justify-cntent-center align-items-center"
      >
        <video autoPlay muted loop>
          <source src={video} type="video/mp4" />
        </video>
        <div
          id="heroCarousel"
          className="carousel carousel-fade container"
          data-ride="carousel"
        >
          <div className="carousel-item active">
            <div className="">
              <div></div>
              <h2 className="animated fadeInDown main-heading">
                <span>Dark Pools</span>
              </h2>
              <p className="animated fadeInUp main-text">Financial Services</p>
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section className="about-section">
          <div className="block">
            <span id="overview-heading">
              Dark Pools Addresses Key Issues Across the Financial Service Value
              Chain
            </span>
            <p className="overview-text">
              Achieving desired Financial results in today's complex, connected
              world requires a high-performance blend of humans with machines,
              automation with artificial intelligence, business analytics with
              data science. Welcome to the Age where Dark Pools translates
              insights into performance through AI services, solutions, and
              capabilities.
            </p>
            <div className="inside-content-wrapper">
              <p className="overview-text">
                Dark Pool's leadership and expertise in automated
                machine learning is helping financial services around the world
                effectively meet industry requirements while simultaneously
                delivering high value smart solutions that increase revenue
                assurance, optimize operations, mitigate risk, Detect anomalies
                and personalize customer experiences.
              </p>
              <div className="image-wrapper">
                <img src={image1} alt={image1} className="main-images"/>
              </div>
            </div>
          </div>
        </section>

        <section className="middle-section">
          <div className="block">
            <div className="panel-block">
              {keyAreaData.map((data, i) => (
                <span key={i} onClick={() => setKeyPoints(data.keyIssues)}>
                  {data.title}
                </span>
              ))}
            </div>
            <div className="finance-panel-content">
              <div className="p-tag">
                <p>Key Issues</p>
              </div>
              <div className="key-issues-container">
                {keyPoints.map((keyIssue, i) => (
                  <div className="key-issues" key={i}>
                      <IconContext.Provider
                        value={{
                          color: "green",
                          className: "global-class-name",
                        }}
                      >
                          <IoMdCheckmarkCircleOutline />
                      </IconContext.Provider>
                      {/* <input type="text" placeholder="Search.." /> */}
                    <span id="spacing">{keyIssue}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default FinancialService;
