import React from "react";
import Navigation from "./components/home-page/navigation";
import  NERoMLPage  from "./components/products/neroml-page/NERoMLPage";
import Footer from './components/footer-section/footer';
import "./App.css";

const NERoML = () => {
  return (
    <div>
      <Navigation />
      <NERoMLPage />
      <Footer />
    </div>
  );
};

export default NERoML;
