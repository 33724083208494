import React from "react";
import Navigation from "./components/home-page/navigation";
import  AimlowProduct  from "./components/products/aimlow-page/AimlowProduct";
import Footer from './components/footer-section/footer';
import "./App.css";

const AIMLow_Products = () => {
  return (
    <div>
      <Navigation />
      <AimlowProduct />
      <Footer />
    </div>
  );
};

export default AIMLow_Products;
